import { BasicLayoutProps } from '@ant-design/pro-layout'
import { MenuDataItem, Route } from '@ant-design/pro-layout/lib/typings'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import {
  ProductProviderMemberRole,
  useProductProviderMemberQuery,
} from '../../generated/graphql'
import { BlankFooter } from './Footer'
import { PageContainer } from './PageContainer'
import { RightContent } from './RightContent'

const AntdBasicLayout = dynamic(
  async () => (await import('@ant-design/pro-layout')).BasicLayout,
  { ssr: false }
)

type Props = Required<Pick<BasicLayoutProps, 'route'>> & {
  user: { email: string }
}

export const BasicLayout: React.FC<Props> = ({ user, route, children }) => {
  const { loading, data } = useProductProviderMemberQuery()
  const router = useRouter()
  const handleMenuHeaderClick = useCallback(() => router.push('/'), [router])
  const handleMenuItemRender = useCallback(
    (options: MenuDataItem, element: React.ReactNode) => (
      <Link href={options.path!}>
        <a>{element}</a>
      </Link>
    ),
    []
  )
  const rightContentRender = useCallback(() => <RightContent user={user} />, [
    user,
  ])

  if (loading || !data) return <PageContainer loading />

  return (
    <AntdBasicLayout
      title="HH cross TOWNS"
      logo={null}
      route={getRouteForRole(route, data?.productProviderMember.role)}
      navTheme="light"
      fixSiderbar
      onMenuHeaderClick={handleMenuHeaderClick}
      menuItemRender={handleMenuItemRender}
      rightContentRender={rightContentRender}
      footerRender={BlankFooter}
      style={layoutStyle}
    >
      {children}
    </AntdBasicLayout>
  )
}

const getRouteForRole = (route: Route, role: ProductProviderMemberRole) => {
  // オーナー
  if (role === 'owner') {
    return route
  }

  // レビュワー
  return {
    routes: route.routes?.filter(
      (item) => item.path === '/simple_pass/my_passes'
    ),
  }
}

const layoutStyle = { minHeight: '100vh' }
