import { Breadcrumb } from 'antd'
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React from 'react'

export const PageContainer = dynamic(
  async () => (await import('@ant-design/pro-layout')).PageContainer,
  { ssr: false }
)

PageContainer.defaultProps = {
  breadcrumbRender: ({ breadcrumb }) => (
    <Breadcrumb>
      {breadcrumb?.routes?.map((route, index, routes) => (
        <BreadcrumbItem key={route.path}>
          {index === routes.length - 1 ? (
            route.breadcrumbName
          ) : (
            <Link href={route.path}>{route.breadcrumbName}</Link>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  ),
}
