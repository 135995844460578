import { UserOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import { Button, Dropdown, Menu, Space, Typography } from 'antd'
import { Auth } from 'aws-amplify'
import Link from 'next/link'
import React, { useMemo } from 'react'

import { useRightContentQuery } from '../../generated/graphql'

type Props = {
  user: { email: string }
}

export const RightContent: React.FC<Props> = ({ user }) => {
  const client = useApolloClient()
  const { data } = useRightContentQuery()
  const productProvider = data?.productProvider

  const menu = useMemo(
    () => (
      <Menu>
        <Menu.Item>
          <Link href="/account_settings">設定</Link>
        </Menu.Item>
        <Menu.Item
          onClick={async () => {
            await Auth.signOut()
            client.resetStore()
          }}
        >
          ログアウト
        </Menu.Item>
      </Menu>
    ),
    [client]
  )

  return (
    <Space>
      <Typography>
        {user.email}｜{productProvider?.name || '...'}
      </Typography>
      <Dropdown overlay={menu}>
        <Button shape="circle" icon={<UserOutlined />} />
      </Dropdown>
    </Space>
  )
}
