import dynamic from 'next/dynamic'
import React from 'react'

const DefaultFooter = dynamic(
  async () => (await import('@ant-design/pro-layout')).DefaultFooter,
  { ssr: false }
)

export const Footer = () => (
  <DefaultFooter links={false} copyright="RYDE, Inc." />
)

export const BlankFooter = () => <div style={footerStyle} />

const footerStyle = {
  marginTop: 16,
  padding: 16,
  width: '100%',
}
