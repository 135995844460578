import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};


export type Area = {
  __typename: 'Area';
  name: Scalars['String'];
  prefectures: Array<Prefecture>;
  slug: Scalars['ID'];
};

/** Autogenerated input type of CreatePlaceScaffold */
export type CreatePlaceScaffoldInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePlaceScaffold. */
export type CreatePlaceScaffoldPayload = {
  __typename: 'CreatePlaceScaffoldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  place: Place;
};

/** Autogenerated input type of CreatePlaceSpec */
export type CreatePlaceSpecInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  nameJa: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePlaceSpec. */
export type CreatePlaceSpecPayload = {
  __typename: 'CreatePlaceSpecPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeSpec: PlaceSpec;
};

/** Autogenerated input type of CreatePlaceTag */
export type CreatePlaceTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  nameJa: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePlaceTag. */
export type CreatePlaceTagPayload = {
  __typename: 'CreatePlaceTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeTag: PlaceTag;
};

/** Autogenerated input type of CreatePresignedPost */
export type CreatePresignedPostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  contentType: Scalars['String'];
};

/** Autogenerated return type of CreatePresignedPost. */
export type CreatePresignedPostPayload = {
  __typename: 'CreatePresignedPostPayload';
  carrierwaveCacheName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  presignedPost: PresignedPost;
};

/** Autogenerated input type of CreateProductScaffold2 */
export type CreateProductScaffold2Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProductScaffold2. */
export type CreateProductScaffold2Payload = {
  __typename: 'CreateProductScaffold2Payload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  product: Product2;
};

/** Autogenerated input type of CreateProductScaffold */
export type CreateProductScaffoldInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateProductScaffold. */
export type CreateProductScaffoldPayload = {
  __typename: 'CreateProductScaffoldPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  product: Product;
};

export type DailyProductSales = {
  __typename: 'DailyProductSales';
  date: Scalars['ISO8601Date'];
  productId: Scalars['ID'];
  productName: Scalars['String'];
  productPriceId: Scalars['ID'];
  productPriceName: Scalars['String'];
  quantity: Scalars['Int'];
  subtotal: Scalars['Int'];
};

export type DailyTicketUsages = {
  __typename: 'DailyTicketUsages';
  date: Scalars['ISO8601Date'];
  productId: Scalars['ID'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  ticketId: Scalars['ID'];
  ticketName: Scalars['String'];
};

export type Dashboard = {
  __typename: 'Dashboard';
  url: Scalars['String'];
};

/** Autogenerated input type of DeletePlace */
export type DeletePlaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DeletePlace. */
export type DeletePlacePayload = {
  __typename: 'DeletePlacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  place: Maybe<Place>;
};

/** Autogenerated input type of DeletePlaceSpec */
export type DeletePlaceSpecInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DeletePlaceSpec. */
export type DeletePlaceSpecPayload = {
  __typename: 'DeletePlaceSpecPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeSpec: Maybe<PlaceSpec>;
};

/** Autogenerated input type of DeletePlaceTag */
export type DeletePlaceTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DeletePlaceTag. */
export type DeletePlaceTagPayload = {
  __typename: 'DeletePlaceTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeTag: Maybe<PlaceTag>;
};

/** Autogenerated input type of DeleteProduct */
export type DeleteProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of DeleteProduct. */
export type DeleteProductPayload = {
  __typename: 'DeleteProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  product: Maybe<Product>;
};

export enum Direction {
  Asc = 'asc',
  Desc = 'desc'
}

export type Geo = {
  __typename: 'Geo';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Holiday = {
  __typename: 'Holiday';
  days: Array<HolidayDay>;
  year: Scalars['Int'];
};

export type HolidayDay = {
  __typename: 'HolidayDay';
  date: Scalars['ISO8601Date'];
  name: Scalars['String'];
};




export type Line = {
  __typename: 'Line';
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  polylines: Array<Scalars['String']>;
  stations: Array<Station>;
};

export type Mutation = {
  __typename: 'Mutation';
  createPlaceScaffold: Maybe<CreatePlaceScaffoldPayload>;
  createPlaceSpec: Maybe<CreatePlaceSpecPayload>;
  createPlaceTag: Maybe<CreatePlaceTagPayload>;
  createPresignedPost: Maybe<CreatePresignedPostPayload>;
  createProductScaffold: Maybe<CreateProductScaffoldPayload>;
  createProductScaffold2: Maybe<CreateProductScaffold2Payload>;
  deletePlace: Maybe<DeletePlacePayload>;
  deletePlaceSpec: Maybe<DeletePlaceSpecPayload>;
  deletePlaceTag: Maybe<DeletePlaceTagPayload>;
  deleteProduct: Maybe<DeleteProductPayload>;
  reviewSimplePassMyPass: Maybe<SimplePassReviewMyPassPayload>;
  updatePlace: Maybe<UpdatePlacePayload>;
  updatePlaceSpec: Maybe<UpdatePlaceSpecPayload>;
  updatePlaceTag: Maybe<UpdatePlaceTagPayload>;
  updateProduct4: Maybe<UpdateProduct4Payload>;
  updateProductProviderMemberSettings: Maybe<UpdateProductProviderMemberSettingsPayload>;
};


export type MutationCreatePlaceScaffoldArgs = {
  input: CreatePlaceScaffoldInput;
};


export type MutationCreatePlaceSpecArgs = {
  input: CreatePlaceSpecInput;
};


export type MutationCreatePlaceTagArgs = {
  input: CreatePlaceTagInput;
};


export type MutationCreatePresignedPostArgs = {
  input: CreatePresignedPostInput;
};


export type MutationCreateProductScaffoldArgs = {
  input: CreateProductScaffoldInput;
};


export type MutationCreateProductScaffold2Args = {
  input: CreateProductScaffold2Input;
};


export type MutationDeletePlaceArgs = {
  input: DeletePlaceInput;
};


export type MutationDeletePlaceSpecArgs = {
  input: DeletePlaceSpecInput;
};


export type MutationDeletePlaceTagArgs = {
  input: DeletePlaceTagInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationReviewSimplePassMyPassArgs = {
  input: SimplePassReviewMyPassInput;
};


export type MutationUpdatePlaceArgs = {
  input: UpdatePlaceInput;
};


export type MutationUpdatePlaceSpecArgs = {
  input: UpdatePlaceSpecInput;
};


export type MutationUpdatePlaceTagArgs = {
  input: UpdatePlaceTagInput;
};


export type MutationUpdateProduct4Args = {
  input: UpdateProduct4Input;
};


export type MutationUpdateProductProviderMemberSettingsArgs = {
  input: UpdateProductProviderMemberSettingsInput;
};

export type Place = {
  __typename: 'Place';
  accessEn: Maybe<Scalars['String']>;
  accessJa: Maybe<Scalars['String']>;
  addressEn: Maybe<Scalars['String']>;
  addressJa: Maybe<Scalars['String']>;
  businessHoursEn: Maybe<Scalars['String']>;
  businessHoursJa: Maybe<Scalars['String']>;
  contentEn: Maybe<Scalars['String']>;
  contentImages: Array<PlaceContentImage>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<PlaceCoverImage>;
  extraEn: Maybe<Scalars['String']>;
  extraJa: Maybe<Scalars['String']>;
  geo: Geo;
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  placeCategory: PlaceCategory;
  placeExternalLinks: Maybe<Array<PlaceExternalLink>>;
  placeSpeccings: Maybe<Array<PlaceSpeccing>>;
  placeTaggings: Maybe<Array<PlaceTagging>>;
  placesAround: Maybe<Array<Place>>;
  regularHolidayEn: Maybe<Scalars['String']>;
  regularHolidayJa: Maybe<Scalars['String']>;
  seatingCapacity: Maybe<Scalars['Int']>;
  status: PlaceStatus;
  vacanPlaceId: Maybe<Scalars['String']>;
  vacanStatus: Maybe<Scalars['String']>;
  vacanTargetKey: Maybe<VacanTargetKey>;
  website: Maybe<Scalars['String']>;
};


export type PlaceContentImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type PlaceCoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type PlacePlaceSpeccingsArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type PlacePlaceTaggingsArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type PlacePlacesAroundArgs = {
  limit: Maybe<Scalars['Int']>;
  distance: Scalars['Int'];
};

export type PlaceCategory = {
  __typename: 'PlaceCategory';
  icon: Scalars['String'];
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  position: Scalars['Int'];
  slug: Scalars['String'];
};

export type PlaceContentImage = {
  __typename: 'PlaceContentImage';
  id: Scalars['ID'];
  image: Scalars['String'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
};

export type PlaceContentImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type PlaceCoverImage = {
  __typename: 'PlaceCoverImage';
  id: Scalars['ID'];
  image: Scalars['String'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
};

export type PlaceCoverImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type PlaceExternalLink = {
  __typename: 'PlaceExternalLink';
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  url: Scalars['String'];
};

export type PlaceExternalLinkInput = {
  id?: Maybe<Scalars['ID']>;
  nameJa?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type PlaceSpec = {
  __typename: 'PlaceSpec';
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
};

export type PlaceSpecInput = {
  id: Scalars['ID'];
  nameJa?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  checked?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
};

export type PlaceSpeccing = {
  __typename: 'PlaceSpeccing';
  checked: Scalars['Boolean'];
  id: Scalars['ID'];
  placeSpec: PlaceSpec;
};

export type PlaceSpeccingInput = {
  id?: Maybe<Scalars['ID']>;
  checked?: Maybe<Scalars['Boolean']>;
  placeSpec?: Maybe<PlaceSpecInput>;
};

export enum PlaceStatus {
  Published = 'published',
  Draft = 'draft'
}

export type PlaceTag = {
  __typename: 'PlaceTag';
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
};

export type PlaceTagInput = {
  id?: Maybe<Scalars['ID']>;
  nameJa?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type PlaceTagging = {
  __typename: 'PlaceTagging';
  id: Scalars['ID'];
  placeTag: PlaceTag;
};

export type PlaceTaggingInput = {
  placeTag?: Maybe<PlaceTagInput>;
};

export type Prefecture = {
  __typename: 'Prefecture';
  area: Area;
  code: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PresignedPost = {
  __typename: 'PresignedPost';
  fields: Scalars['JSON'];
  url: Scalars['String'];
};

export type Product = {
  __typename: 'Product';
  availableDaysEn: Maybe<Scalars['String']>;
  availableDaysJa: Maybe<Scalars['String']>;
  availableTimeMinutes: Scalars['Int'];
  availableTimeOffsetMinutes: Scalars['Int'];
  contentEn: Maybe<Scalars['String']>;
  contentImages: Array<ProductContentImage>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<ProductCoverImage>;
  extraEn: Maybe<Scalars['String']>;
  extraJa: Maybe<Scalars['String']>;
  geo: Geo;
  icon: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  prefecture: Prefecture;
  prices: Array<ProductPrice>;
  productAvailableDaysBaseBlacklistRules: Array<ProductAvailableDaysBaseBlacklistRule>;
  productAvailableDaysOverrideRules: Array<ProductAvailableDaysOverrideRule>;
  provider: ProductProvider;
  purchased: Scalars['Boolean'];
  saleEndAt: Maybe<Scalars['ISO8601DateTime']>;
  saleStartAt: Maybe<Scalars['ISO8601DateTime']>;
  spots: Array<Spot>;
  status: ProductStatus;
  taglineEn: Scalars['String'];
  taglineJa: Scalars['String'];
  tickets2: Array<Ticket2>;
  unavailableDays: Scalars['Int'];
  website: Maybe<Scalars['String']>;
};


export type ProductContentImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type ProductCoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type Product2 = {
  __typename: 'Product2';
  availableDaysJa: Maybe<Scalars['String']>;
  availableTimeMinutes: Scalars['Int'];
  availableTimeOffsetMinutes: Scalars['Int'];
  contentImages: Array<ProductContentImage2>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<ProductCoverImage2>;
  extraJa: Maybe<Scalars['String']>;
  geo: Geo;
  icon: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  prefecture: Prefecture;
  prices: Array<ProductPrice2>;
  productAvailableDaysOverrideRules: Array<ProductAvailableDaysOverrideRule>;
  provider: ProductProvider;
  purchased: Scalars['Boolean'];
  saleEndAt: Maybe<Scalars['ISO8601DateTime']>;
  saleStartAt: Maybe<Scalars['ISO8601DateTime']>;
  spots: Array<Spot2>;
  status: ProductStatus;
  taglineJa: Scalars['String'];
  tickets: Array<Ticket3>;
  unavailableDays: Scalars['Int'];
  website: Maybe<Scalars['String']>;
};


export type Product2ContentImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type Product2CoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type ProductAvailableDaysBaseBlacklistRule = {
  __typename: 'ProductAvailableDaysBaseBlacklistRule';
  dayOfWeek: Scalars['Int'];
  holiday: Scalars['Boolean'];
};

export type ProductAvailableDaysOverrideRule = {
  __typename: 'ProductAvailableDaysOverrideRule';
  available: Scalars['Boolean'];
  date: Scalars['ISO8601Date'];
  id: Scalars['ID'];
};

export type ProductAvailableDaysOverrideRuleInput = {
  id?: Maybe<Scalars['ID']>;
  date: Scalars['ISO8601Date'];
  available: Scalars['Boolean'];
};

export type ProductContentImage = {
  __typename: 'ProductContentImage';
  id: Scalars['ID'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type ProductContentImage2 = {
  __typename: 'ProductContentImage2';
  id: Scalars['ID'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type ProductContentImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type ProductCoverImage = {
  __typename: 'ProductCoverImage';
  id: Scalars['ID'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type ProductCoverImage2 = {
  __typename: 'ProductCoverImage2';
  id: Scalars['ID'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type ProductCoverImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type ProductItem = {
  __typename: 'ProductItem';
  productId: Scalars['ID'];
  productName: Scalars['String'];
  productPriceItems: Array<ProductPriceItem>;
  totalAmount: Scalars['Int'];
  totalCommissionFee: Scalars['Int'];
};

export type ProductPrice = {
  __typename: 'ProductPrice';
  descriptionEn: Maybe<Scalars['String']>;
  descriptionJa: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  unitPrice: Scalars['Int'];
  unitPriceOriginal: Maybe<Scalars['Int']>;
};

export type ProductPrice2 = {
  __typename: 'ProductPrice2';
  descriptionJa: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  unitPrice: Scalars['Int'];
  unitPriceOriginal: Maybe<Scalars['Int']>;
};

export type ProductPriceInput = {
  id?: Maybe<Scalars['ID']>;
  nameJa?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Int']>;
  unitPriceOriginal?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  descriptionJa?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
};

export type ProductPriceItem = {
  __typename: 'ProductPriceItem';
  productPriceId: Scalars['ID'];
  productPriceName: Scalars['String'];
  totalQuantity: Scalars['Int'];
};

export type ProductProvider = {
  __typename: 'ProductProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  products: Array<Product>;
};

export type ProductProviderMember = {
  __typename: 'ProductProviderMember';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  notifySimplePassReviewRequest: Scalars['Boolean'];
  role: ProductProviderMemberRole;
};

export enum ProductProviderMemberRole {
  Owner = 'owner',
  MyPassReviewer = 'my_pass_reviewer'
}

export type ProductSales = {
  __typename: 'ProductSales';
  paymentAmount: Scalars['Int'];
  productItems: Array<ProductItem>;
  totalAmount: Scalars['Int'];
  totalCommissionFee: Scalars['Int'];
};

export enum ProductStatus {
  Published = 'published',
  Draft = 'draft',
  Preview = 'preview',
  Disabled = 'disabled'
}

export type PurchasedProductListItem = {
  __typename: 'PurchasedProductListItem';
  commissionFee: Scalars['Int'];
  id: Scalars['ID'];
  productId: Scalars['ID'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  quantityDetails: Scalars['String'];
  subtotal: Scalars['Int'];
  usageStartAt: Scalars['ISO8601DateTime'];
};

export type PurchasedQuantityListItem = {
  __typename: 'PurchasedQuantityListItem';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  productName: Scalars['String'];
  productPriceId: Scalars['ID'];
  productPriceName: Scalars['String'];
  purchasedProductId: Scalars['ID'];
  quantity: Scalars['Int'];
  unitPrice: Scalars['Int'];
  usageStartAt: Scalars['ISO8601DateTime'];
};

export type Query = {
  __typename: 'Query';
  areas: Array<Area>;
  dailyProductSales: Array<DailyProductSales>;
  dailyTicketUsages: Array<DailyTicketUsages>;
  holidays: Array<Holiday>;
  place: Maybe<Place>;
  placeCategories: Array<PlaceCategory>;
  placeSpec: Maybe<PlaceSpec>;
  placeSpecLists: Maybe<Array<PlaceSpec>>;
  placeTag: Maybe<PlaceTag>;
  placeTagLists: Maybe<Array<PlaceTag>>;
  places: Array<Place>;
  placesTagTotal: Scalars['Int'];
  placesTotal: Scalars['Int'];
  prefectures: Array<Prefecture>;
  product2: Maybe<Product2>;
  productProvider: ProductProvider;
  productProviderMember: ProductProviderMember;
  productReviewsDashboard: Dashboard;
  productSales: ProductSales;
  products2: Array<Product2>;
  purchasedProductListItems: Array<PurchasedProductListItem>;
  purchasedQuantityListItems: Array<PurchasedQuantityListItem>;
  simplePassMyPass: SimplePassMyPass;
  simplePassMyPasses: SimplePassMyPasses;
  simplePassPassGroups: Array<SimplePassPassGroup>;
  simplePassPassTypes: Array<SimplePassPassType>;
  simplePassPurchaseDetailsDashboard: Dashboard;
  simplePassSalesSummaryDashboard: Dashboard;
  simplePassUserDemographicsDashboard: Dashboard;
  ticketCategories: Array<TicketCategory>;
  ticketReviewsDashboard: Dashboard;
  ticketUsageOptions: Array<TicketUsageOption>;
  ticketUsagesDashboard: Dashboard;
  /** @deprecated Use tickets3 */
  tickets2: Array<Ticket3>;
  tickets3: Array<Ticket3>;
  userDemographicsDashboard: Dashboard;
  vacanPlaces: Maybe<Array<VacanPlace>>;
  vacanTargetKeys: Maybe<Array<VacanTargetKey>>;
};


export type QueryDailyProductSalesArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryDailyTicketUsagesArgs = {
  year: Scalars['Int'];
  month: Scalars['Int'];
};


export type QueryPlaceArgs = {
  id: Scalars['ID'];
};


export type QueryPlaceSpecArgs = {
  id: Scalars['ID'];
};


export type QueryPlaceSpecListsArgs = {
  orderType: Maybe<Scalars['String']>;
  sortType: Maybe<Scalars['String']>;
};


export type QueryPlaceTagArgs = {
  id: Scalars['ID'];
};


export type QueryPlaceTagListsArgs = {
  size: Scalars['Int'];
  page: Scalars['Int'];
  orderType: Maybe<Scalars['String']>;
  sortType: Maybe<Scalars['String']>;
};


export type QueryPlacesArgs = {
  size: Scalars['Int'];
  page: Scalars['Int'];
  placeCategoryIds: Maybe<Array<Scalars['ID']>>;
  searchKey: Maybe<Scalars['String']>;
};


export type QueryProduct2Args = {
  id: Scalars['ID'];
};


export type QueryProductReviewsDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  productIds: Array<Scalars['ID']>;
};


export type QueryProductSalesArgs = {
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
};


export type QueryPurchasedProductListItemsArgs = {
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
};


export type QueryPurchasedQuantityListItemsArgs = {
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
};


export type QuerySimplePassMyPassArgs = {
  id: Scalars['ID'];
};


export type QuerySimplePassMyPassesArgs = {
  status: Array<SimplePassMyPassStatus>;
  direction: Maybe<Direction>;
  userGivenName: Maybe<Scalars['String']>;
  userFamilyName: Maybe<Scalars['String']>;
  origin: Maybe<Scalars['String']>;
  destination: Maybe<Scalars['String']>;
  passTypeName: Maybe<Scalars['String']>;
  from: Maybe<Scalars['ISO8601Date']>;
  to: Maybe<Scalars['ISO8601Date']>;
  page: Maybe<Scalars['Int']>;
  size: Maybe<Scalars['Int']>;
};


export type QuerySimplePassPurchaseDetailsDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  simplePassPassGroupIds: Array<Scalars['ID']>;
};


export type QuerySimplePassSalesSummaryDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  passTypeIds: Array<Scalars['ID']>;
};


export type QuerySimplePassUserDemographicsDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  passTypeIds: Array<Scalars['ID']>;
};


export type QueryTicketReviewsDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  ticketIds: Array<Scalars['ID']>;
};


export type QueryTicketUsagesDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  ticketIds: Array<Scalars['ID']>;
};


export type QueryUserDemographicsDashboardArgs = {
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  productIds: Array<Scalars['ID']>;
};


export type QueryVacanPlacesArgs = {
  id: Scalars['ID'];
};

export type SimplePassMyCertificate = {
  __typename: 'SimplePassMyCertificate';
  id: Maybe<Scalars['ID']>;
  myCertificateImages: Array<SimplePassMyCertificateImage>;
  myPasses: Array<SimplePassMyPass>;
  validityEndOn: Maybe<Scalars['String']>;
  validityStartOn: Maybe<Scalars['String']>;
};

export type SimplePassMyCertificateImage = {
  __typename: 'SimplePassMyCertificateImage';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type SimplePassMyPass = {
  __typename: 'SimplePassMyPass';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  maxMyCertificateValidityEndOn: Scalars['ISO8601Date'];
  myCertificate: Maybe<SimplePassMyCertificate>;
  originDestination: SimplePassOriginDestination;
  passPeriod: SimplePassPassPeriod;
  passType: SimplePassPassType;
  paymentAmount: Scalars['Int'];
  status: SimplePassMyPassStatus;
  statusName: Scalars['String'];
  timeline: Array<SimplePassMyPassTimelineItem>;
  usageEndOn: Scalars['ISO8601Date'];
  usageStartOn: Scalars['ISO8601Date'];
  userBirthdate: Scalars['ISO8601Date'];
  userFamilyName: Scalars['String'];
  userGivenName: Scalars['String'];
};

export enum SimplePassMyPassEvent {
  Request = 'request',
  ReviewApproval = 'review_approval',
  ReviewRejection = 'review_rejection',
  PaymentSuccess = 'payment_success',
  Refund = 'refund',
  Suspension = 'suspension',
  Cancel = 'cancel'
}

export enum SimplePassMyPassStatus {
  ReviewRequired = 'review_required',
  ReviewRejected = 'review_rejected',
  PaymentRequired = 'payment_required',
  Succeeded = 'succeeded',
  Canceled = 'canceled',
  Refunded = 'refunded',
  Suspended = 'suspended'
}

export type SimplePassMyPassTimelineItem = {
  __typename: 'SimplePassMyPassTimelineItem';
  detail: Scalars['String'];
  eventType: SimplePassMyPassEvent;
  executedAt: Scalars['ISO8601DateTime'];
  executor: Maybe<ProductProviderMember>;
};

export type SimplePassMyPasses = {
  __typename: 'SimplePassMyPasses';
  data: Array<SimplePassMyPass>;
  page: Scalars['Int'];
  size: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type SimplePassOriginDestination = {
  __typename: 'SimplePassOriginDestination';
  destination: SimplePassStop;
  id: Scalars['ID'];
  origin: SimplePassStop;
  via: Maybe<SimplePassVia>;
};

export type SimplePassPassGroup = {
  __typename: 'SimplePassPassGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameKana: Scalars['String'];
  passTypeDescription: Maybe<Scalars['String']>;
  passTypes: Array<SimplePassPassType>;
};

export type SimplePassPassPeriod = {
  __typename: 'SimplePassPassPeriod';
  id: Scalars['ID'];
  periodType: Scalars['Int'];
};

export type SimplePassPassType = {
  __typename: 'SimplePassPassType';
  ageCategoryName: Scalars['String'];
  fullname: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  passGroup: SimplePassPassGroup;
};

/** Autogenerated input type of SimplePassReviewMyPass */
export type SimplePassReviewMyPassInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reviewResult: SimplePassReviewResult;
  myCertificateValidityEndOn?: Maybe<Scalars['ISO8601Date']>;
  reviewRejectReason?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SimplePassReviewMyPass. */
export type SimplePassReviewMyPassPayload = {
  __typename: 'SimplePassReviewMyPassPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  simplePassMyPass: SimplePassMyPass;
};

export enum SimplePassReviewResult {
  Approve = 'approve',
  Reject = 'reject'
}

export type SimplePassStop = {
  __typename: 'SimplePassStop';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameKana: Scalars['String'];
};

export type SimplePassVia = {
  __typename: 'SimplePassVia';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameKana: Scalars['String'];
};

export type Spot = {
  __typename: 'Spot';
  accessEn: Maybe<Scalars['String']>;
  accessJa: Maybe<Scalars['String']>;
  addressEn: Maybe<Scalars['String']>;
  addressJa: Maybe<Scalars['String']>;
  contentEn: Maybe<Scalars['String']>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<SpotCoverImage>;
  extraEn: Maybe<Scalars['String']>;
  extraJa: Maybe<Scalars['String']>;
  geo: Geo;
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  tickets: Array<Ticket2>;
  website: Maybe<Scalars['String']>;
};


export type SpotCoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type Spot2 = {
  __typename: 'Spot2';
  accessJa: Maybe<Scalars['String']>;
  addressJa: Maybe<Scalars['String']>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<SpotCoverImage2>;
  extraJa: Maybe<Scalars['String']>;
  geo: Geo;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  tickets: Array<Ticket3>;
  website: Maybe<Scalars['String']>;
};


export type Spot2CoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type SpotCoverImage = {
  __typename: 'SpotCoverImage';
  id: Scalars['ID'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type SpotCoverImage2 = {
  __typename: 'SpotCoverImage2';
  id: Scalars['ID'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type SpotCoverImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type SpotInput = {
  id?: Maybe<Scalars['ID']>;
  accessJa?: Maybe<Scalars['String']>;
  accessEn?: Maybe<Scalars['String']>;
  addressJa?: Maybe<Scalars['String']>;
  addressEn?: Maybe<Scalars['String']>;
  contentJa?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  coverImages?: Maybe<Array<SpotCoverImageInput>>;
  extraJa?: Maybe<Scalars['String']>;
  extraEn?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoInput>;
  nameJa?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  website?: Maybe<Scalars['String']>;
};

export type Station = {
  __typename: 'Station';
  geo: Geo;
  id: Scalars['ID'];
  lines: Array<Line>;
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
};

export type Ticket2 = {
  __typename: 'Ticket2';
  color: Maybe<Scalars['String']>;
  contentEn: Maybe<Scalars['String']>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<TicketCoverImage>;
  extraEn: Maybe<Scalars['String']>;
  extraJa: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  lines: Array<Line>;
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  product: Product;
  purchased: Scalars['Boolean'];
  spots: Array<Spot>;
  ticketCategory: TicketCategory;
  ticketType: TypeForTicket;
  ticketUsageOptions: Array<TicketUsageOption>;
  usageLimit: Maybe<Scalars['Int']>;
  usageType: UsageType;
  website: Maybe<Scalars['String']>;
};


export type Ticket2CoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type Ticket3 = {
  __typename: 'Ticket3';
  color: Maybe<Scalars['String']>;
  contentJa: Maybe<Scalars['String']>;
  coverImages: Array<TicketCoverImage2>;
  extraJa: Maybe<Scalars['String']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  lines: Array<Line>;
  nameJa: Scalars['String'];
  phoneNumber: Maybe<Scalars['String']>;
  product: Product2;
  purchased: Scalars['Boolean'];
  spots: Array<Spot2>;
  ticketCategory: TicketCategory;
  ticketType: TypeForTicket;
  ticketUsageOptions: Array<TicketUsageOption>;
  usageLimit: Maybe<Scalars['Int']>;
  usageType: UsageType;
  website: Maybe<Scalars['String']>;
};


export type Ticket3CoverImagesArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type Ticket3Input = {
  id?: Maybe<Scalars['ID']>;
  color?: Maybe<Scalars['String']>;
  contentJa?: Maybe<Scalars['String']>;
  coverImages?: Maybe<Array<TicketCoverImageInput>>;
  extraJa?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  spots?: Maybe<Array<SpotInput>>;
  usageLimit?: Maybe<Scalars['Int']>;
  usageType?: Maybe<UsageType>;
  ticketType?: Maybe<TypeForTicket>;
  ticketCategoryId: Scalars['ID'];
  website?: Maybe<Scalars['String']>;
};

export type TicketCategory = {
  __typename: 'TicketCategory';
  color: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  isTransportCategory: Scalars['Boolean'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  slug: Scalars['String'];
  watermarkAnimation: Scalars['String'];
};

export type TicketCoverImage = {
  __typename: 'TicketCoverImage';
  id: Scalars['ID'];
  textEn: Scalars['String'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type TicketCoverImage2 = {
  __typename: 'TicketCoverImage2';
  id: Scalars['ID'];
  textJa: Scalars['String'];
  url: Scalars['String'];
};

export type TicketCoverImageInput = {
  id?: Maybe<Scalars['ID']>;
  carrierwaveCacheName?: Maybe<Scalars['String']>;
  textJa?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
};

export type TicketUsageOption = {
  __typename: 'TicketUsageOption';
  id: Scalars['ID'];
  nameEn: Scalars['String'];
  nameJa: Scalars['String'];
  tickets: Array<Ticket2>;
};

export enum TypeForTicket {
  PassengerTicket = 'passenger_ticket',
  NotPassengerTicket = 'not_passenger_ticket'
}

/** Autogenerated input type of UpdatePlace */
export type UpdatePlaceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  accessJa?: Maybe<Scalars['String']>;
  accessEn?: Maybe<Scalars['String']>;
  addressJa?: Maybe<Scalars['String']>;
  addressEn?: Maybe<Scalars['String']>;
  businessHoursJa?: Maybe<Scalars['String']>;
  businessHoursEn?: Maybe<Scalars['String']>;
  contentJa?: Maybe<Scalars['String']>;
  contentEn?: Maybe<Scalars['String']>;
  extraJa?: Maybe<Scalars['String']>;
  extraEn?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoInput>;
  nameJa: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regularHolidayJa?: Maybe<Scalars['String']>;
  regularHolidayEn?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  coverImages?: Maybe<Array<PlaceCoverImageInput>>;
  contentImages?: Maybe<Array<PlaceContentImageInput>>;
  placeCategoryId: Scalars['ID'];
  placeTaggings?: Maybe<Array<PlaceTaggingInput>>;
  placeSpeccings?: Maybe<Array<PlaceSpeccingInput>>;
  placeExternalLinks?: Maybe<Array<PlaceExternalLinkInput>>;
  seatingCapacity?: Maybe<Scalars['Int']>;
  vacanTargetKey?: Maybe<Scalars['String']>;
  vacanPlaceId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePlace. */
export type UpdatePlacePayload = {
  __typename: 'UpdatePlacePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  place: Place;
};

/** Autogenerated input type of UpdatePlaceSpec */
export type UpdatePlaceSpecInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePlaceSpec. */
export type UpdatePlaceSpecPayload = {
  __typename: 'UpdatePlaceSpecPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeSpec: PlaceSpec;
};

/** Autogenerated input type of UpdatePlaceTag */
export type UpdatePlaceTagInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdatePlaceTag. */
export type UpdatePlaceTagPayload = {
  __typename: 'UpdatePlaceTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  placeTag: PlaceTag;
};

/** Autogenerated input type of UpdateProduct4 */
export type UpdateProduct4Input = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  prefectureSlug?: Maybe<Scalars['String']>;
  availableTimeOffsetMinutes?: Maybe<Scalars['Int']>;
  availableTimeMinutes?: Maybe<Scalars['Int']>;
  contentJa?: Maybe<Scalars['String']>;
  contentImages?: Maybe<Array<ProductContentImageInput>>;
  coverImages?: Maybe<Array<ProductCoverImageInput>>;
  extraJa?: Maybe<Scalars['String']>;
  geo?: Maybe<GeoInput>;
  icon?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  saleStartAt?: Maybe<Scalars['ISO8601DateTime']>;
  saleEndAt?: Maybe<Scalars['ISO8601DateTime']>;
  taglineJa?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<ProductPriceInput>>;
  tickets?: Maybe<Array<Ticket3Input>>;
  unavailableDays: Scalars['Int'];
  productAvailableDaysOverrideRules?: Maybe<Array<ProductAvailableDaysOverrideRuleInput>>;
};

/** Autogenerated return type of UpdateProduct4. */
export type UpdateProduct4Payload = {
  __typename: 'UpdateProduct4Payload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  product: Product2;
};

/** Autogenerated input type of UpdateProductProviderMemberSettings */
export type UpdateProductProviderMemberSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notifySimplePassReviewRequest: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateProductProviderMemberSettings. */
export type UpdateProductProviderMemberSettingsPayload = {
  __typename: 'UpdateProductProviderMemberSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  productProviderMember: ProductProviderMember;
};

export enum UsageType {
  Normal = 'normal',
  QrMpm = 'qr_mpm',
  QrCpm = 'qr_cpm'
}

export type VacanPlace = {
  __typename: 'VacanPlace';
  checked: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  placeIdHash: Scalars['String'];
};

export type VacanTargetKey = {
  __typename: 'VacanTargetKey';
  id: Scalars['ID'];
  targetKey: Scalars['String'];
  vacanPlaceTotal: Maybe<Scalars['Int']>;
  vacanPlaceUsed: Maybe<Scalars['Int']>;
};

export type AreaFieldsFragment = (
  { __typename: 'Area' }
  & Pick<Area, 'slug' | 'name'>
);

export type LineFieldsFragment = (
  { __typename: 'Line' }
  & Pick<Line, 'id' | 'name' | 'color' | 'polylines'>
);

export type PlaceCategoryFieldsFragment = (
  { __typename: 'PlaceCategory' }
  & Pick<PlaceCategory, 'id' | 'icon' | 'position' | 'nameJa' | 'slug'>
);

export type PlaceContentImageFieldsFragment = (
  { __typename: 'PlaceContentImage' }
  & Pick<PlaceContentImage, 'id' | 'image' | 'textJa'>
);

export type PlaceCoverImageFieldsFragment = (
  { __typename: 'PlaceCoverImage' }
  & Pick<PlaceCoverImage, 'id' | 'image' | 'textJa'>
);

export type PlaceExternalLinkFieldsFragment = (
  { __typename: 'PlaceExternalLink' }
  & Pick<PlaceExternalLink, 'id' | 'nameJa' | 'url'>
);

export type PlaceFieldsFragment = (
  { __typename: 'Place' }
  & Pick<Place, 'id' | 'nameJa' | 'businessHoursJa' | 'addressJa' | 'phoneNumber' | 'extraJa' | 'regularHolidayJa' | 'website' | 'contentJa' | 'accessJa' | 'status' | 'seatingCapacity' | 'vacanStatus' | 'vacanPlaceId'>
  & { geo: (
    { __typename: 'Geo' }
    & Pick<Geo, 'longitude' | 'latitude'>
  ) }
);

export type PlaceSpecFieldsFragment = (
  { __typename: 'PlaceSpec' }
  & Pick<PlaceSpec, 'id' | 'nameJa'>
);

export type PlaceSpeccingFieldsFragment = (
  { __typename: 'PlaceSpeccing' }
  & Pick<PlaceSpeccing, 'id' | 'checked'>
);

export type PlaceTagFieldsFragment = (
  { __typename: 'PlaceTag' }
  & Pick<PlaceTag, 'id' | 'nameJa'>
);

export type PlaceTagsFieldsFragment = (
  { __typename: 'PlaceTag' }
  & Pick<PlaceTag, 'id' | 'nameJa'>
);

export type ProductAvailableDaysOverrideRuleFieldsFragment = (
  { __typename: 'ProductAvailableDaysOverrideRule' }
  & Pick<ProductAvailableDaysOverrideRule, 'id' | 'date' | 'available'>
);

export type ProductContentImageFieldsFragment = (
  { __typename: 'ProductContentImage2' }
  & Pick<ProductContentImage2, 'id' | 'url' | 'textJa'>
);

export type ProductCoverImageFieldsFragment = (
  { __typename: 'ProductCoverImage2' }
  & Pick<ProductCoverImage2, 'id' | 'url' | 'textJa'>
);

export type ProductFieldsFragment = (
  { __typename: 'Product2' }
  & Pick<Product2, 'id' | 'availableTimeOffsetMinutes' | 'availableTimeMinutes' | 'contentJa' | 'extraJa' | 'icon' | 'nameJa' | 'purchased' | 'saleEndAt' | 'saleStartAt' | 'status' | 'taglineJa' | 'website'>
  & { geo: (
    { __typename: 'Geo' }
    & Pick<Geo, 'latitude' | 'longitude'>
  ) }
);

export type ProductPriceFieldsFragment = (
  { __typename: 'ProductPrice2' }
  & Pick<ProductPrice2, 'id' | 'nameJa' | 'unitPrice' | 'descriptionJa'>
);

export type ProductProviderFieldsFragment = (
  { __typename: 'ProductProvider' }
  & Pick<ProductProvider, 'id' | 'name'>
);

export type ProductProviderMemberFieldsFragment = (
  { __typename: 'ProductProviderMember' }
  & Pick<ProductProviderMember, 'id' | 'role' | 'notifySimplePassReviewRequest'>
);

export type ProductSalesFieldsFragment = (
  { __typename: 'ProductSales' }
  & Pick<ProductSales, 'totalAmount' | 'totalCommissionFee' | 'paymentAmount'>
  & { productItems: Array<(
    { __typename: 'ProductItem' }
    & Pick<ProductItem, 'productId' | 'productName' | 'totalAmount' | 'totalCommissionFee'>
    & { productPriceItems: Array<(
      { __typename: 'ProductPriceItem' }
      & Pick<ProductPriceItem, 'productPriceId' | 'productPriceName' | 'totalQuantity'>
    )> }
  )> }
);

export type SimplePassDestinationFieldsFragment = (
  { __typename: 'SimplePassStop' }
  & Pick<SimplePassStop, 'id' | 'name'>
);

export type SimplePassMyCertificateFieldsFragment = (
  { __typename: 'SimplePassMyCertificate' }
  & Pick<SimplePassMyCertificate, 'validityEndOn' | 'validityStartOn'>
  & { myCertificateImages: Array<(
    { __typename: 'SimplePassMyCertificateImage' }
    & Pick<SimplePassMyCertificateImage, 'url'>
  )> }
);

export type SimplePassMyPassFieldsFragment = (
  { __typename: 'SimplePassMyPass' }
  & Pick<SimplePassMyPass, 'id' | 'status' | 'statusName' | 'createdAt' | 'userBirthdate' | 'userGivenName' | 'userFamilyName' | 'paymentAmount' | 'usageStartOn' | 'usageEndOn'>
  & { timeline: Array<(
    { __typename: 'SimplePassMyPassTimelineItem' }
    & Pick<SimplePassMyPassTimelineItem, 'eventType' | 'detail' | 'executedAt'>
    & { executor: Maybe<(
      { __typename: 'ProductProviderMember' }
      & Pick<ProductProviderMember, 'id' | 'name'>
    )> }
  )> }
);

export type SimplePassOriginFieldsFragment = (
  { __typename: 'SimplePassStop' }
  & Pick<SimplePassStop, 'id' | 'name'>
);

export type SimplePassPassGroupFieldsFragment = (
  { __typename: 'SimplePassPassGroup' }
  & Pick<SimplePassPassGroup, 'name'>
);

export type SimplePassPassPeriodFieldsFragment = (
  { __typename: 'SimplePassPassPeriod' }
  & Pick<SimplePassPassPeriod, 'periodType'>
);

export type SimplePassPassTypeFieldsFragment = (
  { __typename: 'SimplePassPassType' }
  & Pick<SimplePassPassType, 'name' | 'ageCategoryName'>
);

export type SimplePassViaFieldsFragment = (
  { __typename: 'SimplePassVia' }
  & Pick<SimplePassVia, 'name' | 'nameKana'>
);

export type SpotCoverImageFieldsFragment = (
  { __typename: 'SpotCoverImage2' }
  & Pick<SpotCoverImage2, 'id' | 'url' | 'textJa'>
);

export type SpotFieldsFragment = (
  { __typename: 'Spot2' }
  & Pick<Spot2, 'id' | 'accessJa' | 'addressJa' | 'contentJa' | 'extraJa' | 'nameJa' | 'phoneNumber' | 'website'>
  & { geo: (
    { __typename: 'Geo' }
    & Pick<Geo, 'latitude' | 'longitude'>
  ) }
);

export type StationFieldsFragment = (
  { __typename: 'Station' }
  & Pick<Station, 'id' | 'nameJa'>
  & { geo: (
    { __typename: 'Geo' }
    & Pick<Geo, 'latitude' | 'longitude'>
  ) }
);

export type TicketCategoryFieldsFragment = (
  { __typename: 'TicketCategory' }
  & Pick<TicketCategory, 'id' | 'nameJa' | 'slug' | 'icon' | 'color' | 'watermarkAnimation' | 'isTransportCategory'>
);

export type TicketCoverImageFieldsFragment = (
  { __typename: 'TicketCoverImage2' }
  & Pick<TicketCoverImage2, 'id' | 'textJa' | 'url'>
);

export type TicketFieldsFragment = (
  { __typename: 'Ticket3' }
  & Pick<Ticket3, 'id' | 'color' | 'contentJa' | 'extraJa' | 'icon' | 'nameJa' | 'phoneNumber' | 'purchased' | 'usageLimit' | 'usageType' | 'ticketType' | 'website'>
);

export type TicketUsageOptionFieldsFragment = (
  { __typename: 'TicketUsageOption' }
  & Pick<TicketUsageOption, 'id' | 'nameJa'>
);

export type VacanPlaceIdFieldsFragment = (
  { __typename: 'VacanPlace' }
  & Pick<VacanPlace, 'id' | 'placeIdHash' | 'checked'>
);

export type VacanTargetKeyFieldsFragment = (
  { __typename: 'VacanTargetKey' }
  & Pick<VacanTargetKey, 'id' | 'targetKey' | 'vacanPlaceUsed' | 'vacanPlaceTotal'>
);

export type CreatePlaceScaffoldMutationVariables = Exact<{ [key: string]: never; }>;


export type CreatePlaceScaffoldMutation = (
  { __typename: 'Mutation' }
  & { createPlaceScaffold: Maybe<(
    { __typename: 'CreatePlaceScaffoldPayload' }
    & { place: (
      { __typename: 'Place' }
      & PlaceFieldsFragment
    ) }
  )> }
);

export type CreatePlaceSpecMutationVariables = Exact<{
  input: CreatePlaceSpecInput;
}>;


export type CreatePlaceSpecMutation = (
  { __typename: 'Mutation' }
  & { createPlaceSpec: Maybe<(
    { __typename: 'CreatePlaceSpecPayload' }
    & { placeSpec: (
      { __typename: 'PlaceSpec' }
      & PlaceSpecFieldsFragment
    ) }
  )> }
);

export type CreatePlaceTagMutationVariables = Exact<{
  input: CreatePlaceTagInput;
}>;


export type CreatePlaceTagMutation = (
  { __typename: 'Mutation' }
  & { createPlaceTag: Maybe<(
    { __typename: 'CreatePlaceTagPayload' }
    & { placeTag: (
      { __typename: 'PlaceTag' }
      & PlaceTagFieldsFragment
    ) }
  )> }
);

export type CreatePresignedPostMutationVariables = Exact<{
  input: CreatePresignedPostInput;
}>;


export type CreatePresignedPostMutation = (
  { __typename: 'Mutation' }
  & { createPresignedPost: Maybe<(
    { __typename: 'CreatePresignedPostPayload' }
    & Pick<CreatePresignedPostPayload, 'carrierwaveCacheName'>
    & { presignedPost: (
      { __typename: 'PresignedPost' }
      & Pick<PresignedPost, 'fields' | 'url'>
    ) }
  )> }
);

export type CreateProductScaffoldMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateProductScaffoldMutation = (
  { __typename: 'Mutation' }
  & { createProductScaffold: Maybe<(
    { __typename: 'CreateProductScaffold2Payload' }
    & { product: (
      { __typename: 'Product2' }
      & { tickets: Array<(
        { __typename: 'Ticket3' }
        & TicketFieldsFragment
      )> }
      & ProductFieldsFragment
    ) }
  )> }
);

export type DeletePlaceMutationVariables = Exact<{
  id: Maybe<Scalars['ID']>;
}>;


export type DeletePlaceMutation = (
  { __typename: 'Mutation' }
  & { deletePlace: Maybe<(
    { __typename: 'DeletePlacePayload' }
    & { place: Maybe<(
      { __typename: 'Place' }
      & Pick<Place, 'id' | 'nameJa'>
    )> }
  )> }
);

export type DeletePlaceSpecMutationVariables = Exact<{
  id: Maybe<Scalars['ID']>;
}>;


export type DeletePlaceSpecMutation = (
  { __typename: 'Mutation' }
  & { deletePlaceSpec: Maybe<(
    { __typename: 'DeletePlaceSpecPayload' }
    & { placeSpec: Maybe<(
      { __typename: 'PlaceSpec' }
      & Pick<PlaceSpec, 'id' | 'nameJa'>
    )> }
  )> }
);

export type DeletePlaceTagMutationVariables = Exact<{
  id: Maybe<Scalars['ID']>;
}>;


export type DeletePlaceTagMutation = (
  { __typename: 'Mutation' }
  & { deletePlaceTag: Maybe<(
    { __typename: 'DeletePlaceTagPayload' }
    & { placeTag: Maybe<(
      { __typename: 'PlaceTag' }
      & Pick<PlaceTag, 'id' | 'nameJa'>
    )> }
  )> }
);

export type DeleteProductMutationVariables = Exact<{
  id: Maybe<Scalars['ID']>;
}>;


export type DeleteProductMutation = (
  { __typename: 'Mutation' }
  & { deleteProduct: Maybe<(
    { __typename: 'DeleteProductPayload' }
    & { product: Maybe<(
      { __typename: 'Product' }
      & Pick<Product, 'id'>
    )> }
  )> }
);

export type ReviewSimplePassMyPassMutationVariables = Exact<{
  input: SimplePassReviewMyPassInput;
}>;


export type ReviewSimplePassMyPassMutation = (
  { __typename: 'Mutation' }
  & { reviewSimplePassMyPass: Maybe<(
    { __typename: 'SimplePassReviewMyPassPayload' }
    & { simplePassMyPass: (
      { __typename: 'SimplePassMyPass' }
      & SimplePassMyPassFieldsFragment
    ) }
  )> }
);

export type UpdatePlaceMutationVariables = Exact<{
  input: UpdatePlaceInput;
}>;


export type UpdatePlaceMutation = (
  { __typename: 'Mutation' }
  & { updatePlace: Maybe<(
    { __typename: 'UpdatePlacePayload' }
    & { place: (
      { __typename: 'Place' }
      & { contentImages: Array<(
        { __typename: 'PlaceContentImage' }
        & PlaceContentImageFieldsFragment
      )>, coverImages: Array<(
        { __typename: 'PlaceCoverImage' }
        & PlaceCoverImageFieldsFragment
      )>, placeTaggings: Maybe<Array<(
        { __typename: 'PlaceTagging' }
        & { placeTag: (
          { __typename: 'PlaceTag' }
          & PlaceTagFieldsFragment
        ) }
      )>>, placeExternalLinks: Maybe<Array<(
        { __typename: 'PlaceExternalLink' }
        & PlaceExternalLinkFieldsFragment
      )>>, placeSpeccings: Maybe<Array<(
        { __typename: 'PlaceSpeccing' }
        & { placeSpec: (
          { __typename: 'PlaceSpec' }
          & PlaceSpecFieldsFragment
        ) }
        & PlaceSpeccingFieldsFragment
      )>>, placeCategory: (
        { __typename: 'PlaceCategory' }
        & PlaceCategoryFieldsFragment
      ), vacanTargetKey: Maybe<(
        { __typename: 'VacanTargetKey' }
        & VacanTargetKeyFieldsFragment
      )> }
      & PlaceFieldsFragment
    ) }
  )> }
);

export type UpdatePlaceSpecMutationVariables = Exact<{
  input: UpdatePlaceSpecInput;
}>;


export type UpdatePlaceSpecMutation = (
  { __typename: 'Mutation' }
  & { updatePlaceSpec: Maybe<(
    { __typename: 'UpdatePlaceSpecPayload' }
    & { placeSpec: (
      { __typename: 'PlaceSpec' }
      & PlaceSpecFieldsFragment
    ) }
  )> }
);

export type UpdatePlaceTagMutationVariables = Exact<{
  input: UpdatePlaceTagInput;
}>;


export type UpdatePlaceTagMutation = (
  { __typename: 'Mutation' }
  & { updatePlaceTag: Maybe<(
    { __typename: 'UpdatePlaceTagPayload' }
    & { placeTag: (
      { __typename: 'PlaceTag' }
      & PlaceTagFieldsFragment
    ) }
  )> }
);

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProduct4Input;
}>;


export type UpdateProductMutation = (
  { __typename: 'Mutation' }
  & { updateProduct: Maybe<(
    { __typename: 'UpdateProduct4Payload' }
    & { product: (
      { __typename: 'Product2' }
      & Pick<Product2, 'unavailableDays'>
      & { prefecture: (
        { __typename: 'Prefecture' }
        & Pick<Prefecture, 'slug' | 'name'>
      ), contentImages: Array<(
        { __typename: 'ProductContentImage2' }
        & ProductContentImageFieldsFragment
      )>, coverImages: Array<(
        { __typename: 'ProductCoverImage2' }
        & ProductCoverImageFieldsFragment
      )>, prices: Array<(
        { __typename: 'ProductPrice2' }
        & ProductPriceFieldsFragment
      )>, productAvailableDaysOverrideRules: Array<(
        { __typename: 'ProductAvailableDaysOverrideRule' }
        & ProductAvailableDaysOverrideRuleFieldsFragment
      )>, tickets: Array<(
        { __typename: 'Ticket3' }
        & { coverImages: Array<(
          { __typename: 'TicketCoverImage2' }
          & TicketCoverImageFieldsFragment
        )>, spots: Array<(
          { __typename: 'Spot2' }
          & { coverImages: Array<(
            { __typename: 'SpotCoverImage2' }
            & SpotCoverImageFieldsFragment
          )> }
          & SpotFieldsFragment
        )>, ticketUsageOptions: Array<(
          { __typename: 'TicketUsageOption' }
          & TicketUsageOptionFieldsFragment
        )>, ticketCategory: (
          { __typename: 'TicketCategory' }
          & TicketCategoryFieldsFragment
        ) }
        & TicketFieldsFragment
      )> }
      & ProductFieldsFragment
    ) }
  )> }
);

export type UpdateProductProviderMemberSettingsMutationVariables = Exact<{
  input: UpdateProductProviderMemberSettingsInput;
}>;


export type UpdateProductProviderMemberSettingsMutation = (
  { __typename: 'Mutation' }
  & { updateProductProviderMemberSettings: Maybe<(
    { __typename: 'UpdateProductProviderMemberSettingsPayload' }
    & { productProviderMember: (
      { __typename: 'ProductProviderMember' }
      & Pick<ProductProviderMember, 'notifySimplePassReviewRequest'>
    ) }
  )> }
);

export type AnalyticsProductReviewsDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  productIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AnalyticsProductReviewsDashboardQuery = (
  { __typename: 'Query' }
  & { productReviewsDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type AnalyticsProductReviewsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsProductReviewsPageQuery = (
  { __typename: 'Query' }
  & { products: Array<(
    { __typename: 'Product2' }
    & Pick<Product2, 'id'>
    & { name: Product2['nameJa'] }
  )> }
);

export type AnalyticsTicketReviewsDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  ticketIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AnalyticsTicketReviewsDashboardQuery = (
  { __typename: 'Query' }
  & { ticketReviewsDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type AnalyticsTicketReviewsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsTicketReviewsPageQuery = (
  { __typename: 'Query' }
  & { tickets: Array<(
    { __typename: 'Ticket3' }
    & Pick<Ticket3, 'id'>
    & { name: Ticket3['nameJa'] }
    & { product: (
      { __typename: 'Product2' }
      & Pick<Product2, 'id'>
      & { name: Product2['nameJa'] }
    ) }
  )> }
);

export type AnalyticsTicketUsagesDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  ticketIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AnalyticsTicketUsagesDashboardQuery = (
  { __typename: 'Query' }
  & { ticketUsagesDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type AnalyticsTicketUsagesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsTicketUsagesPageQuery = (
  { __typename: 'Query' }
  & { tickets: Array<(
    { __typename: 'Ticket3' }
    & Pick<Ticket3, 'id'>
    & { name: Ticket3['nameJa'] }
    & { product: (
      { __typename: 'Product2' }
      & Pick<Product2, 'id'>
      & { name: Product2['nameJa'] }
    ) }
  )> }
);

export type AnalyticsUserDemographicsDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  productIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AnalyticsUserDemographicsDashboardQuery = (
  { __typename: 'Query' }
  & { userDemographicsDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type AnalyticsUserDemographicsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AnalyticsUserDemographicsPageQuery = (
  { __typename: 'Query' }
  & { products: Array<(
    { __typename: 'Product2' }
    & Pick<Product2, 'id'>
    & { name: Product2['nameJa'] }
  )> }
);

export type IndexPageQueryVariables = Exact<{
  thisMonthFrom: Scalars['ISO8601DateTime'];
  thisMonthTo: Scalars['ISO8601DateTime'];
  todayFrom: Scalars['ISO8601DateTime'];
  todayTo: Scalars['ISO8601DateTime'];
}>;


export type IndexPageQuery = (
  { __typename: 'Query' }
  & { thisMonthProductSales: (
    { __typename: 'ProductSales' }
    & ProductSalesFieldsFragment
  ), todayProductSales: (
    { __typename: 'ProductSales' }
    & ProductSalesFieldsFragment
  ), productProviderMember: (
    { __typename: 'ProductProviderMember' }
    & ProductProviderMemberFieldsFragment
  ) }
);

export type PlaceCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type PlaceCategoriesQuery = (
  { __typename: 'Query' }
  & { placeCategories: Array<(
    { __typename: 'PlaceCategory' }
    & PlaceCategoryFieldsFragment
  )> }
);

export type PlaceEditPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlaceEditPageQuery = (
  { __typename: 'Query' }
  & { place: Maybe<(
    { __typename: 'Place' }
    & { placeCategory: (
      { __typename: 'PlaceCategory' }
      & PlaceCategoryFieldsFragment
    ), coverImages: Array<(
      { __typename: 'PlaceCoverImage' }
      & PlaceCoverImageFieldsFragment
    )>, contentImages: Array<(
      { __typename: 'PlaceContentImage' }
      & PlaceContentImageFieldsFragment
    )>, placeTaggings: Maybe<Array<(
      { __typename: 'PlaceTagging' }
      & { placeTag: (
        { __typename: 'PlaceTag' }
        & PlaceTagFieldsFragment
      ) }
    )>>, placeExternalLinks: Maybe<Array<(
      { __typename: 'PlaceExternalLink' }
      & PlaceExternalLinkFieldsFragment
    )>>, placeSpeccings: Maybe<Array<(
      { __typename: 'PlaceSpeccing' }
      & { placeSpec: (
        { __typename: 'PlaceSpec' }
        & PlaceSpecFieldsFragment
      ) }
      & PlaceSpeccingFieldsFragment
    )>>, vacanTargetKey: Maybe<(
      { __typename: 'VacanTargetKey' }
      & VacanTargetKeyFieldsFragment
    )> }
    & PlaceFieldsFragment
  )>, placeCategories: Array<(
    { __typename: 'PlaceCategory' }
    & PlaceCategoryFieldsFragment
  )>, placeTagLists: Maybe<Array<(
    { __typename: 'PlaceTag' }
    & PlaceTagFieldsFragment
  )>>, placeSpecLists: Maybe<Array<(
    { __typename: 'PlaceSpec' }
    & PlaceSpecFieldsFragment
  )>> }
);

export type PlaceSpecDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlaceSpecDetailQuery = (
  { __typename: 'Query' }
  & { placeSpec: Maybe<(
    { __typename: 'PlaceSpec' }
    & Pick<PlaceSpec, 'id' | 'nameJa'>
  )> }
);

export type PlaceSpecsPageQueryVariables = Exact<{
  orderType: Scalars['String'];
  sortType: Scalars['String'];
}>;


export type PlaceSpecsPageQuery = (
  { __typename: 'Query' }
  & { placeSpecLists: Maybe<Array<(
    { __typename: 'PlaceSpec' }
    & Pick<PlaceSpec, 'id' | 'nameJa'>
  )>> }
);

export type PlaceTagDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlaceTagDetailQuery = (
  { __typename: 'Query' }
  & { placeTag: Maybe<(
    { __typename: 'PlaceTag' }
    & Pick<PlaceTag, 'id' | 'nameJa'>
  )> }
);

export type PlaceTagsPageQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  sortType: Scalars['String'];
  orderType: Scalars['String'];
}>;


export type PlaceTagsPageQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'placesTagTotal'>
  & { placeTagLists: Maybe<Array<(
    { __typename: 'PlaceTag' }
    & Pick<PlaceTag, 'id' | 'nameJa'>
  )>> }
);

export type PlacesPageQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  searchKey: Scalars['String'];
  placeCategoryIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type PlacesPageQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'placesTotal'>
  & { places: Array<(
    { __typename: 'Place' }
    & { coverImages: Array<(
      { __typename: 'PlaceCoverImage' }
      & PlaceCoverImageFieldsFragment
    )>, placeCategory: (
      { __typename: 'PlaceCategory' }
      & PlaceCategoryFieldsFragment
    ) }
    & PlaceFieldsFragment
  )>, placeCategories: Array<(
    { __typename: 'PlaceCategory' }
    & PlaceCategoryFieldsFragment
  )> }
);

export type ProductEditPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductEditPageQuery = (
  { __typename: 'Query' }
  & { product: Maybe<(
    { __typename: 'Product2' }
    & Pick<Product2, 'unavailableDays'>
    & { prefecture: (
      { __typename: 'Prefecture' }
      & Pick<Prefecture, 'slug' | 'name'>
    ), contentImages: Array<(
      { __typename: 'ProductContentImage2' }
      & ProductContentImageFieldsFragment
    )>, coverImages: Array<(
      { __typename: 'ProductCoverImage2' }
      & ProductCoverImageFieldsFragment
    )>, prices: Array<(
      { __typename: 'ProductPrice2' }
      & ProductPriceFieldsFragment
    )>, productAvailableDaysOverrideRules: Array<(
      { __typename: 'ProductAvailableDaysOverrideRule' }
      & ProductAvailableDaysOverrideRuleFieldsFragment
    )>, tickets: Array<(
      { __typename: 'Ticket3' }
      & { coverImages: Array<(
        { __typename: 'TicketCoverImage2' }
        & TicketCoverImageFieldsFragment
      )>, spots: Array<(
        { __typename: 'Spot2' }
        & { coverImages: Array<(
          { __typename: 'SpotCoverImage2' }
          & SpotCoverImageFieldsFragment
        )> }
        & SpotFieldsFragment
      )>, ticketUsageOptions: Array<(
        { __typename: 'TicketUsageOption' }
        & TicketUsageOptionFieldsFragment
      )>, ticketCategory: (
        { __typename: 'TicketCategory' }
        & TicketCategoryFieldsFragment
      ) }
      & TicketFieldsFragment
    )> }
    & ProductFieldsFragment
  )>, ticketCategories: Array<(
    { __typename: 'TicketCategory' }
    & TicketCategoryFieldsFragment
  )>, prefectures: Array<(
    { __typename: 'Prefecture' }
    & Pick<Prefecture, 'slug' | 'name'>
  )>, holidays: Array<(
    { __typename: 'Holiday' }
    & Pick<Holiday, 'year'>
    & { days: Array<(
      { __typename: 'HolidayDay' }
      & Pick<HolidayDay, 'name' | 'date'>
    )> }
  )> }
);

export type ProductProviderMemberQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductProviderMemberQuery = (
  { __typename: 'Query' }
  & { productProviderMember: (
    { __typename: 'ProductProviderMember' }
    & ProductProviderMemberFieldsFragment
  ) }
);

export type ProductsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsPageQuery = (
  { __typename: 'Query' }
  & { products: Array<(
    { __typename: 'Product2' }
    & { coverImages: Array<(
      { __typename: 'ProductCoverImage2' }
      & ProductCoverImageFieldsFragment
    )> }
    & ProductFieldsFragment
  )> }
);

export type PurchasedProductsIndexPageQueryVariables = Exact<{
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
}>;


export type PurchasedProductsIndexPageQuery = (
  { __typename: 'Query' }
  & { purchasedProductListItems: Array<(
    { __typename: 'PurchasedProductListItem' }
    & Pick<PurchasedProductListItem, 'id' | 'usageStartAt' | 'productId' | 'productName' | 'subtotal' | 'commissionFee' | 'quantity' | 'quantityDetails'>
  )>, productProvider: (
    { __typename: 'ProductProvider' }
    & ProductProviderFieldsFragment
  ) }
);

export type PushNotificationIndexPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PushNotificationIndexPageQuery = (
  { __typename: 'Query' }
  & { productProvider: (
    { __typename: 'ProductProvider' }
    & ProductProviderFieldsFragment
  ) }
);

export type RightContentQueryVariables = Exact<{ [key: string]: never; }>;


export type RightContentQuery = (
  { __typename: 'Query' }
  & { productProvider: (
    { __typename: 'ProductProvider' }
    & ProductProviderFieldsFragment
  ) }
);

export type SalesDailyProductSalesIndexPageQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type SalesDailyProductSalesIndexPageQuery = (
  { __typename: 'Query' }
  & { dailyProductSales: Array<(
    { __typename: 'DailyProductSales' }
    & Pick<DailyProductSales, 'date' | 'productId' | 'productName' | 'productPriceId' | 'productPriceName' | 'quantity' | 'subtotal'>
  )>, productProvider: (
    { __typename: 'ProductProvider' }
    & ProductProviderFieldsFragment
  ) }
);

export type SalesDailyTicketUsagesIndexPageQueryVariables = Exact<{
  year: Scalars['Int'];
  month: Scalars['Int'];
}>;


export type SalesDailyTicketUsagesIndexPageQuery = (
  { __typename: 'Query' }
  & { dailyTicketUsages: Array<(
    { __typename: 'DailyTicketUsages' }
    & Pick<DailyTicketUsages, 'date' | 'productId' | 'productName' | 'ticketId' | 'ticketName' | 'quantity'>
  )>, productProvider: (
    { __typename: 'ProductProvider' }
    & ProductProviderFieldsFragment
  ) }
);

export type SalesSummaryIndexPageQueryVariables = Exact<{
  from: Scalars['ISO8601DateTime'];
  to: Scalars['ISO8601DateTime'];
}>;


export type SalesSummaryIndexPageQuery = (
  { __typename: 'Query' }
  & { productSales: (
    { __typename: 'ProductSales' }
    & ProductSalesFieldsFragment
  ) }
);

export type SimplePassMyPassDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SimplePassMyPassDetailQuery = (
  { __typename: 'Query' }
  & { simplePassMyPass: (
    { __typename: 'SimplePassMyPass' }
    & Pick<SimplePassMyPass, 'maxMyCertificateValidityEndOn'>
    & { originDestination: (
      { __typename: 'SimplePassOriginDestination' }
      & { origin: (
        { __typename: 'SimplePassStop' }
        & SimplePassOriginFieldsFragment
      ), destination: (
        { __typename: 'SimplePassStop' }
        & SimplePassDestinationFieldsFragment
      ), via: Maybe<(
        { __typename: 'SimplePassVia' }
        & SimplePassViaFieldsFragment
      )> }
    ), passPeriod: (
      { __typename: 'SimplePassPassPeriod' }
      & SimplePassPassPeriodFieldsFragment
    ), passType: (
      { __typename: 'SimplePassPassType' }
      & SimplePassPassTypeFieldsFragment
    ), myCertificate: Maybe<(
      { __typename: 'SimplePassMyCertificate' }
      & { myPasses: Array<(
        { __typename: 'SimplePassMyPass' }
        & Pick<SimplePassMyPass, 'id' | 'statusName' | 'usageStartOn' | 'createdAt'>
        & { passPeriod: (
          { __typename: 'SimplePassPassPeriod' }
          & Pick<SimplePassPassPeriod, 'periodType'>
        ) }
      )> }
      & SimplePassMyCertificateFieldsFragment
    )> }
    & SimplePassMyPassFieldsFragment
  ) }
);

export type SimplePassSalesSummaryDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  passTypeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SimplePassSalesSummaryDashboardQuery = (
  { __typename: 'Query' }
  & { simplePassSalesSummaryDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type SimplePassSalesSummaryPageQueryVariables = Exact<{ [key: string]: never; }>;


export type SimplePassSalesSummaryPageQuery = (
  { __typename: 'Query' }
  & { simplePassPassTypes: Array<(
    { __typename: 'SimplePassPassType' }
    & Pick<SimplePassPassType, 'id' | 'fullname'>
    & { passGroup: (
      { __typename: 'SimplePassPassGroup' }
      & SimplePassPassGroupFieldsFragment
    ) }
  )> }
);

export type SimplePassUserDemographicsDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  passTypeIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SimplePassUserDemographicsDashboardQuery = (
  { __typename: 'Query' }
  & { simplePassUserDemographicsDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type SimplePassUserDemographicsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type SimplePassUserDemographicsPageQuery = (
  { __typename: 'Query' }
  & { simplePassPassTypes: Array<(
    { __typename: 'SimplePassPassType' }
    & Pick<SimplePassPassType, 'id' | 'fullname'>
    & { passGroup: (
      { __typename: 'SimplePassPassGroup' }
      & SimplePassPassGroupFieldsFragment
    ) }
  )> }
);

export type VacanPlaceIdHashPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VacanPlaceIdHashPageQuery = (
  { __typename: 'Query' }
  & { vacanPlaces: Maybe<Array<(
    { __typename: 'VacanPlace' }
    & Pick<VacanPlace, 'id' | 'name' | 'placeIdHash' | 'checked'>
  )>> }
);

export type VacanTargetKeyPageQueryVariables = Exact<{ [key: string]: never; }>;


export type VacanTargetKeyPageQuery = (
  { __typename: 'Query' }
  & { vacanTargetKeys: Maybe<Array<(
    { __typename: 'VacanTargetKey' }
    & Pick<VacanTargetKey, 'id' | 'targetKey' | 'vacanPlaceUsed' | 'vacanPlaceTotal'>
  )>> }
);

export type GenerateQrPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GenerateQrPageQuery = (
  { __typename: 'Query' }
  & { productProvider: (
    { __typename: 'ProductProvider' }
    & Pick<ProductProvider, 'id' | 'name'>
  ), ticketUsageOptions: Array<(
    { __typename: 'TicketUsageOption' }
    & Pick<TicketUsageOption, 'id' | 'nameJa'>
    & { tickets: Array<(
      { __typename: 'Ticket2' }
      & Pick<Ticket2, 'id' | 'nameJa'>
      & { product: (
        { __typename: 'Product' }
        & Pick<Product, 'id' | 'nameJa'>
      ) }
    )> }
  )> }
);

export type SimplePassMyPassesPageQueryVariables = Exact<{
  status: Array<SimplePassMyPassStatus> | SimplePassMyPassStatus;
  direction: Maybe<Direction>;
  userGivenName: Maybe<Scalars['String']>;
  userFamilyName: Maybe<Scalars['String']>;
  origin: Maybe<Scalars['String']>;
  destination: Maybe<Scalars['String']>;
  passTypeName: Maybe<Scalars['String']>;
  from: Maybe<Scalars['ISO8601Date']>;
  to: Maybe<Scalars['ISO8601Date']>;
  page: Maybe<Scalars['Int']>;
  size: Maybe<Scalars['Int']>;
}>;


export type SimplePassMyPassesPageQuery = (
  { __typename: 'Query' }
  & { simplePassMyPasses: (
    { __typename: 'SimplePassMyPasses' }
    & Pick<SimplePassMyPasses, 'totalCount' | 'size' | 'page'>
    & { data: Array<(
      { __typename: 'SimplePassMyPass' }
      & Pick<SimplePassMyPass, 'id' | 'statusName' | 'createdAt' | 'userGivenName' | 'userFamilyName' | 'usageStartOn'>
      & { originDestination: (
        { __typename: 'SimplePassOriginDestination' }
        & { origin: (
          { __typename: 'SimplePassStop' }
          & SimplePassOriginFieldsFragment
        ), destination: (
          { __typename: 'SimplePassStop' }
          & SimplePassDestinationFieldsFragment
        ) }
      ), passPeriod: (
        { __typename: 'SimplePassPassPeriod' }
        & Pick<SimplePassPassPeriod, 'periodType'>
      ), passType: (
        { __typename: 'SimplePassPassType' }
        & Pick<SimplePassPassType, 'name' | 'ageCategoryName'>
      ) }
    )> }
  ) }
);

export type SimplePassPurchaseDetailsDashboardQueryVariables = Exact<{
  startOn: Scalars['ISO8601Date'];
  endOn: Scalars['ISO8601Date'];
  simplePassPassGroupIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SimplePassPurchaseDetailsDashboardQuery = (
  { __typename: 'Query' }
  & { simplePassPurchaseDetailsDashboard: (
    { __typename: 'Dashboard' }
    & Pick<Dashboard, 'url'>
  ) }
);

export type SimplePassPurchaseDetailsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type SimplePassPurchaseDetailsPageQuery = (
  { __typename: 'Query' }
  & { simplePassPassGroups: Array<(
    { __typename: 'SimplePassPassGroup' }
    & Pick<SimplePassPassGroup, 'id' | 'name'>
  )> }
);

export const AreaFieldsFragmentDoc = gql`
    fragment AreaFields on Area {
  slug
  name
}
    `;
export const LineFieldsFragmentDoc = gql`
    fragment LineFields on Line {
  id
  name
  color
  polylines
}
    `;
export const PlaceCategoryFieldsFragmentDoc = gql`
    fragment PlaceCategoryFields on PlaceCategory {
  id
  icon
  position
  nameJa
  slug
}
    `;
export const PlaceContentImageFieldsFragmentDoc = gql`
    fragment PlaceContentImageFields on PlaceContentImage {
  id
  image
  textJa
}
    `;
export const PlaceCoverImageFieldsFragmentDoc = gql`
    fragment PlaceCoverImageFields on PlaceCoverImage {
  id
  image
  textJa
}
    `;
export const PlaceExternalLinkFieldsFragmentDoc = gql`
    fragment PlaceExternalLinkFields on PlaceExternalLink {
  id
  nameJa
  url
}
    `;
export const PlaceFieldsFragmentDoc = gql`
    fragment PlaceFields on Place {
  id
  nameJa
  businessHoursJa
  addressJa
  phoneNumber
  extraJa
  regularHolidayJa
  website
  contentJa
  accessJa
  status
  geo {
    longitude
    latitude
  }
  seatingCapacity
  vacanStatus
  vacanPlaceId
}
    `;
export const PlaceSpecFieldsFragmentDoc = gql`
    fragment PlaceSpecFields on PlaceSpec {
  id
  nameJa
}
    `;
export const PlaceSpeccingFieldsFragmentDoc = gql`
    fragment PlaceSpeccingFields on PlaceSpeccing {
  id
  checked
}
    `;
export const PlaceTagFieldsFragmentDoc = gql`
    fragment PlaceTagFields on PlaceTag {
  id
  nameJa
}
    `;
export const PlaceTagsFieldsFragmentDoc = gql`
    fragment PlaceTagsFields on PlaceTag {
  id
  nameJa
}
    `;
export const ProductAvailableDaysOverrideRuleFieldsFragmentDoc = gql`
    fragment ProductAvailableDaysOverrideRuleFields on ProductAvailableDaysOverrideRule {
  id
  date
  available
}
    `;
export const ProductContentImageFieldsFragmentDoc = gql`
    fragment ProductContentImageFields on ProductContentImage2 {
  id
  url
  textJa
}
    `;
export const ProductCoverImageFieldsFragmentDoc = gql`
    fragment ProductCoverImageFields on ProductCoverImage2 {
  id
  url
  textJa
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product2 {
  id
  availableTimeOffsetMinutes
  availableTimeMinutes
  contentJa
  extraJa
  geo {
    latitude
    longitude
  }
  icon
  nameJa
  purchased
  saleEndAt
  saleStartAt
  status
  taglineJa
  website
}
    `;
export const ProductPriceFieldsFragmentDoc = gql`
    fragment ProductPriceFields on ProductPrice2 {
  id
  nameJa
  unitPrice
  descriptionJa
}
    `;
export const ProductProviderFieldsFragmentDoc = gql`
    fragment ProductProviderFields on ProductProvider {
  id
  name
}
    `;
export const ProductProviderMemberFieldsFragmentDoc = gql`
    fragment ProductProviderMemberFields on ProductProviderMember {
  id
  role
  notifySimplePassReviewRequest
}
    `;
export const ProductSalesFieldsFragmentDoc = gql`
    fragment ProductSalesFields on ProductSales {
  totalAmount
  totalCommissionFee
  paymentAmount
  productItems {
    productId
    productName
    totalAmount
    totalCommissionFee
    productPriceItems {
      productPriceId
      productPriceName
      totalQuantity
    }
  }
}
    `;
export const SimplePassDestinationFieldsFragmentDoc = gql`
    fragment SimplePassDestinationFields on SimplePassStop {
  id
  name
}
    `;
export const SimplePassMyCertificateFieldsFragmentDoc = gql`
    fragment SimplePassMyCertificateFields on SimplePassMyCertificate {
  validityEndOn
  validityStartOn
  myCertificateImages {
    url
  }
}
    `;
export const SimplePassMyPassFieldsFragmentDoc = gql`
    fragment SimplePassMyPassFields on SimplePassMyPass {
  id
  status
  statusName
  createdAt
  userBirthdate
  userGivenName
  userFamilyName
  paymentAmount
  usageStartOn
  usageEndOn
  timeline {
    eventType
    detail
    executedAt
    executor {
      id
      name
    }
  }
}
    `;
export const SimplePassOriginFieldsFragmentDoc = gql`
    fragment SimplePassOriginFields on SimplePassStop {
  id
  name
}
    `;
export const SimplePassPassGroupFieldsFragmentDoc = gql`
    fragment SimplePassPassGroupFields on SimplePassPassGroup {
  name
}
    `;
export const SimplePassPassPeriodFieldsFragmentDoc = gql`
    fragment SimplePassPassPeriodFields on SimplePassPassPeriod {
  periodType
}
    `;
export const SimplePassPassTypeFieldsFragmentDoc = gql`
    fragment SimplePassPassTypeFields on SimplePassPassType {
  name
  ageCategoryName
}
    `;
export const SimplePassViaFieldsFragmentDoc = gql`
    fragment SimplePassViaFields on SimplePassVia {
  name
  nameKana
}
    `;
export const SpotCoverImageFieldsFragmentDoc = gql`
    fragment SpotCoverImageFields on SpotCoverImage2 {
  id
  url
  textJa
}
    `;
export const SpotFieldsFragmentDoc = gql`
    fragment SpotFields on Spot2 {
  id
  accessJa
  addressJa
  contentJa
  extraJa
  geo {
    latitude
    longitude
  }
  nameJa
  phoneNumber
  website
}
    `;
export const StationFieldsFragmentDoc = gql`
    fragment StationFields on Station {
  id
  nameJa
  geo {
    latitude
    longitude
  }
}
    `;
export const TicketCategoryFieldsFragmentDoc = gql`
    fragment TicketCategoryFields on TicketCategory {
  id
  nameJa
  slug
  icon
  color
  watermarkAnimation
  isTransportCategory
}
    `;
export const TicketCoverImageFieldsFragmentDoc = gql`
    fragment TicketCoverImageFields on TicketCoverImage2 {
  id
  textJa
  url
}
    `;
export const TicketFieldsFragmentDoc = gql`
    fragment TicketFields on Ticket3 {
  id
  color
  contentJa
  extraJa
  icon
  nameJa
  phoneNumber
  purchased
  usageLimit
  usageType
  ticketType
  website
}
    `;
export const TicketUsageOptionFieldsFragmentDoc = gql`
    fragment TicketUsageOptionFields on TicketUsageOption {
  id
  nameJa
}
    `;
export const VacanPlaceIdFieldsFragmentDoc = gql`
    fragment VacanPlaceIdFields on VacanPlace {
  id
  placeIdHash
  checked
}
    `;
export const VacanTargetKeyFieldsFragmentDoc = gql`
    fragment VacanTargetKeyFields on VacanTargetKey {
  id
  targetKey
  vacanPlaceUsed
  vacanPlaceTotal
}
    `;
export const CreatePlaceScaffoldDocument = gql`
    mutation CreatePlaceScaffold {
  createPlaceScaffold(input: {}) {
    place {
      ...PlaceFields
    }
  }
}
    ${PlaceFieldsFragmentDoc}`;
export type CreatePlaceScaffoldMutationFn = Apollo.MutationFunction<CreatePlaceScaffoldMutation, CreatePlaceScaffoldMutationVariables>;

/**
 * __useCreatePlaceScaffoldMutation__
 *
 * To run a mutation, you first call `useCreatePlaceScaffoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceScaffoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceScaffoldMutation, { data, loading, error }] = useCreatePlaceScaffoldMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePlaceScaffoldMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceScaffoldMutation, CreatePlaceScaffoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceScaffoldMutation, CreatePlaceScaffoldMutationVariables>(CreatePlaceScaffoldDocument, options);
      }
export type CreatePlaceScaffoldMutationHookResult = ReturnType<typeof useCreatePlaceScaffoldMutation>;
export type CreatePlaceScaffoldMutationResult = Apollo.MutationResult<CreatePlaceScaffoldMutation>;
export type CreatePlaceScaffoldMutationOptions = Apollo.BaseMutationOptions<CreatePlaceScaffoldMutation, CreatePlaceScaffoldMutationVariables>;
export const CreatePlaceSpecDocument = gql`
    mutation CreatePlaceSpec($input: CreatePlaceSpecInput!) {
  createPlaceSpec(input: $input) {
    placeSpec {
      ...PlaceSpecFields
    }
  }
}
    ${PlaceSpecFieldsFragmentDoc}`;
export type CreatePlaceSpecMutationFn = Apollo.MutationFunction<CreatePlaceSpecMutation, CreatePlaceSpecMutationVariables>;

/**
 * __useCreatePlaceSpecMutation__
 *
 * To run a mutation, you first call `useCreatePlaceSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceSpecMutation, { data, loading, error }] = useCreatePlaceSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaceSpecMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceSpecMutation, CreatePlaceSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceSpecMutation, CreatePlaceSpecMutationVariables>(CreatePlaceSpecDocument, options);
      }
export type CreatePlaceSpecMutationHookResult = ReturnType<typeof useCreatePlaceSpecMutation>;
export type CreatePlaceSpecMutationResult = Apollo.MutationResult<CreatePlaceSpecMutation>;
export type CreatePlaceSpecMutationOptions = Apollo.BaseMutationOptions<CreatePlaceSpecMutation, CreatePlaceSpecMutationVariables>;
export const CreatePlaceTagDocument = gql`
    mutation CreatePlaceTag($input: CreatePlaceTagInput!) {
  createPlaceTag(input: $input) {
    placeTag {
      ...PlaceTagFields
    }
  }
}
    ${PlaceTagFieldsFragmentDoc}`;
export type CreatePlaceTagMutationFn = Apollo.MutationFunction<CreatePlaceTagMutation, CreatePlaceTagMutationVariables>;

/**
 * __useCreatePlaceTagMutation__
 *
 * To run a mutation, you first call `useCreatePlaceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceTagMutation, { data, loading, error }] = useCreatePlaceTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlaceTagMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceTagMutation, CreatePlaceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceTagMutation, CreatePlaceTagMutationVariables>(CreatePlaceTagDocument, options);
      }
export type CreatePlaceTagMutationHookResult = ReturnType<typeof useCreatePlaceTagMutation>;
export type CreatePlaceTagMutationResult = Apollo.MutationResult<CreatePlaceTagMutation>;
export type CreatePlaceTagMutationOptions = Apollo.BaseMutationOptions<CreatePlaceTagMutation, CreatePlaceTagMutationVariables>;
export const CreatePresignedPostDocument = gql`
    mutation CreatePresignedPost($input: CreatePresignedPostInput!) {
  createPresignedPost(input: $input) {
    presignedPost {
      fields
      url
    }
    carrierwaveCacheName
  }
}
    `;
export type CreatePresignedPostMutationFn = Apollo.MutationFunction<CreatePresignedPostMutation, CreatePresignedPostMutationVariables>;

/**
 * __useCreatePresignedPostMutation__
 *
 * To run a mutation, you first call `useCreatePresignedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresignedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresignedPostMutation, { data, loading, error }] = useCreatePresignedPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresignedPostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePresignedPostMutation, CreatePresignedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePresignedPostMutation, CreatePresignedPostMutationVariables>(CreatePresignedPostDocument, options);
      }
export type CreatePresignedPostMutationHookResult = ReturnType<typeof useCreatePresignedPostMutation>;
export type CreatePresignedPostMutationResult = Apollo.MutationResult<CreatePresignedPostMutation>;
export type CreatePresignedPostMutationOptions = Apollo.BaseMutationOptions<CreatePresignedPostMutation, CreatePresignedPostMutationVariables>;
export const CreateProductScaffoldDocument = gql`
    mutation CreateProductScaffold {
  createProductScaffold: createProductScaffold2(input: {}) {
    product {
      ...ProductFields
      tickets {
        ...TicketFields
      }
    }
  }
}
    ${ProductFieldsFragmentDoc}
${TicketFieldsFragmentDoc}`;
export type CreateProductScaffoldMutationFn = Apollo.MutationFunction<CreateProductScaffoldMutation, CreateProductScaffoldMutationVariables>;

/**
 * __useCreateProductScaffoldMutation__
 *
 * To run a mutation, you first call `useCreateProductScaffoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductScaffoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductScaffoldMutation, { data, loading, error }] = useCreateProductScaffoldMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateProductScaffoldMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductScaffoldMutation, CreateProductScaffoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductScaffoldMutation, CreateProductScaffoldMutationVariables>(CreateProductScaffoldDocument, options);
      }
export type CreateProductScaffoldMutationHookResult = ReturnType<typeof useCreateProductScaffoldMutation>;
export type CreateProductScaffoldMutationResult = Apollo.MutationResult<CreateProductScaffoldMutation>;
export type CreateProductScaffoldMutationOptions = Apollo.BaseMutationOptions<CreateProductScaffoldMutation, CreateProductScaffoldMutationVariables>;
export const DeletePlaceDocument = gql`
    mutation DeletePlace($id: ID) {
  deletePlace(input: {id: $id}) {
    place {
      id
      nameJa
    }
  }
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, options);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const DeletePlaceSpecDocument = gql`
    mutation DeletePlaceSpec($id: ID) {
  deletePlaceSpec(input: {id: $id}) {
    placeSpec {
      id
      nameJa
    }
  }
}
    `;
export type DeletePlaceSpecMutationFn = Apollo.MutationFunction<DeletePlaceSpecMutation, DeletePlaceSpecMutationVariables>;

/**
 * __useDeletePlaceSpecMutation__
 *
 * To run a mutation, you first call `useDeletePlaceSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceSpecMutation, { data, loading, error }] = useDeletePlaceSpecMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaceSpecMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceSpecMutation, DeletePlaceSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceSpecMutation, DeletePlaceSpecMutationVariables>(DeletePlaceSpecDocument, options);
      }
export type DeletePlaceSpecMutationHookResult = ReturnType<typeof useDeletePlaceSpecMutation>;
export type DeletePlaceSpecMutationResult = Apollo.MutationResult<DeletePlaceSpecMutation>;
export type DeletePlaceSpecMutationOptions = Apollo.BaseMutationOptions<DeletePlaceSpecMutation, DeletePlaceSpecMutationVariables>;
export const DeletePlaceTagDocument = gql`
    mutation DeletePlaceTag($id: ID) {
  deletePlaceTag(input: {id: $id}) {
    placeTag {
      id
      nameJa
    }
  }
}
    `;
export type DeletePlaceTagMutationFn = Apollo.MutationFunction<DeletePlaceTagMutation, DeletePlaceTagMutationVariables>;

/**
 * __useDeletePlaceTagMutation__
 *
 * To run a mutation, you first call `useDeletePlaceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceTagMutation, { data, loading, error }] = useDeletePlaceTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaceTagMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceTagMutation, DeletePlaceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceTagMutation, DeletePlaceTagMutationVariables>(DeletePlaceTagDocument, options);
      }
export type DeletePlaceTagMutationHookResult = ReturnType<typeof useDeletePlaceTagMutation>;
export type DeletePlaceTagMutationResult = Apollo.MutationResult<DeletePlaceTagMutation>;
export type DeletePlaceTagMutationOptions = Apollo.BaseMutationOptions<DeletePlaceTagMutation, DeletePlaceTagMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: ID) {
  deleteProduct(input: {id: $id}) {
    product {
      id
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const ReviewSimplePassMyPassDocument = gql`
    mutation ReviewSimplePassMyPass($input: SimplePassReviewMyPassInput!) {
  reviewSimplePassMyPass(input: $input) {
    simplePassMyPass {
      ...SimplePassMyPassFields
    }
  }
}
    ${SimplePassMyPassFieldsFragmentDoc}`;
export type ReviewSimplePassMyPassMutationFn = Apollo.MutationFunction<ReviewSimplePassMyPassMutation, ReviewSimplePassMyPassMutationVariables>;

/**
 * __useReviewSimplePassMyPassMutation__
 *
 * To run a mutation, you first call `useReviewSimplePassMyPassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewSimplePassMyPassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewSimplePassMyPassMutation, { data, loading, error }] = useReviewSimplePassMyPassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewSimplePassMyPassMutation(baseOptions?: Apollo.MutationHookOptions<ReviewSimplePassMyPassMutation, ReviewSimplePassMyPassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewSimplePassMyPassMutation, ReviewSimplePassMyPassMutationVariables>(ReviewSimplePassMyPassDocument, options);
      }
export type ReviewSimplePassMyPassMutationHookResult = ReturnType<typeof useReviewSimplePassMyPassMutation>;
export type ReviewSimplePassMyPassMutationResult = Apollo.MutationResult<ReviewSimplePassMyPassMutation>;
export type ReviewSimplePassMyPassMutationOptions = Apollo.BaseMutationOptions<ReviewSimplePassMyPassMutation, ReviewSimplePassMyPassMutationVariables>;
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($input: UpdatePlaceInput!) {
  updatePlace(input: $input) {
    place {
      ...PlaceFields
      contentImages {
        ...PlaceContentImageFields
      }
      coverImages {
        ...PlaceCoverImageFields
      }
      placeTaggings {
        placeTag {
          ...PlaceTagFields
        }
      }
      placeExternalLinks {
        ...PlaceExternalLinkFields
      }
      placeSpeccings {
        ...PlaceSpeccingFields
        placeSpec {
          ...PlaceSpecFields
        }
      }
      placeCategory {
        ...PlaceCategoryFields
      }
      vacanTargetKey {
        ...VacanTargetKeyFields
      }
    }
  }
}
    ${PlaceFieldsFragmentDoc}
${PlaceContentImageFieldsFragmentDoc}
${PlaceCoverImageFieldsFragmentDoc}
${PlaceTagFieldsFragmentDoc}
${PlaceExternalLinkFieldsFragmentDoc}
${PlaceSpeccingFieldsFragmentDoc}
${PlaceSpecFieldsFragmentDoc}
${PlaceCategoryFieldsFragmentDoc}
${VacanTargetKeyFieldsFragmentDoc}`;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const UpdatePlaceSpecDocument = gql`
    mutation UpdatePlaceSpec($input: UpdatePlaceSpecInput!) {
  updatePlaceSpec(input: $input) {
    placeSpec {
      ...PlaceSpecFields
    }
  }
}
    ${PlaceSpecFieldsFragmentDoc}`;
export type UpdatePlaceSpecMutationFn = Apollo.MutationFunction<UpdatePlaceSpecMutation, UpdatePlaceSpecMutationVariables>;

/**
 * __useUpdatePlaceSpecMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceSpecMutation, { data, loading, error }] = useUpdatePlaceSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceSpecMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceSpecMutation, UpdatePlaceSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceSpecMutation, UpdatePlaceSpecMutationVariables>(UpdatePlaceSpecDocument, options);
      }
export type UpdatePlaceSpecMutationHookResult = ReturnType<typeof useUpdatePlaceSpecMutation>;
export type UpdatePlaceSpecMutationResult = Apollo.MutationResult<UpdatePlaceSpecMutation>;
export type UpdatePlaceSpecMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceSpecMutation, UpdatePlaceSpecMutationVariables>;
export const UpdatePlaceTagDocument = gql`
    mutation UpdatePlaceTag($input: UpdatePlaceTagInput!) {
  updatePlaceTag(input: $input) {
    placeTag {
      ...PlaceTagFields
    }
  }
}
    ${PlaceTagFieldsFragmentDoc}`;
export type UpdatePlaceTagMutationFn = Apollo.MutationFunction<UpdatePlaceTagMutation, UpdatePlaceTagMutationVariables>;

/**
 * __useUpdatePlaceTagMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceTagMutation, { data, loading, error }] = useUpdatePlaceTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceTagMutation, UpdatePlaceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceTagMutation, UpdatePlaceTagMutationVariables>(UpdatePlaceTagDocument, options);
      }
export type UpdatePlaceTagMutationHookResult = ReturnType<typeof useUpdatePlaceTagMutation>;
export type UpdatePlaceTagMutationResult = Apollo.MutationResult<UpdatePlaceTagMutation>;
export type UpdatePlaceTagMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceTagMutation, UpdatePlaceTagMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: UpdateProduct4Input!) {
  updateProduct: updateProduct4(input: $input) {
    product {
      ...ProductFields
      prefecture {
        slug
        name
      }
      contentImages {
        ...ProductContentImageFields
      }
      coverImages {
        ...ProductCoverImageFields
      }
      prices {
        ...ProductPriceFields
      }
      unavailableDays
      productAvailableDaysOverrideRules {
        ...ProductAvailableDaysOverrideRuleFields
      }
      tickets {
        ...TicketFields
        coverImages {
          ...TicketCoverImageFields
        }
        spots {
          ...SpotFields
          coverImages {
            ...SpotCoverImageFields
          }
        }
        ticketUsageOptions {
          ...TicketUsageOptionFields
        }
        ticketCategory {
          ...TicketCategoryFields
        }
      }
    }
  }
}
    ${ProductFieldsFragmentDoc}
${ProductContentImageFieldsFragmentDoc}
${ProductCoverImageFieldsFragmentDoc}
${ProductPriceFieldsFragmentDoc}
${ProductAvailableDaysOverrideRuleFieldsFragmentDoc}
${TicketFieldsFragmentDoc}
${TicketCoverImageFieldsFragmentDoc}
${SpotFieldsFragmentDoc}
${SpotCoverImageFieldsFragmentDoc}
${TicketUsageOptionFieldsFragmentDoc}
${TicketCategoryFieldsFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateProductProviderMemberSettingsDocument = gql`
    mutation UpdateProductProviderMemberSettings($input: UpdateProductProviderMemberSettingsInput!) {
  updateProductProviderMemberSettings(input: $input) {
    productProviderMember {
      notifySimplePassReviewRequest
    }
  }
}
    `;
export type UpdateProductProviderMemberSettingsMutationFn = Apollo.MutationFunction<UpdateProductProviderMemberSettingsMutation, UpdateProductProviderMemberSettingsMutationVariables>;

/**
 * __useUpdateProductProviderMemberSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProductProviderMemberSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductProviderMemberSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductProviderMemberSettingsMutation, { data, loading, error }] = useUpdateProductProviderMemberSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductProviderMemberSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductProviderMemberSettingsMutation, UpdateProductProviderMemberSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductProviderMemberSettingsMutation, UpdateProductProviderMemberSettingsMutationVariables>(UpdateProductProviderMemberSettingsDocument, options);
      }
export type UpdateProductProviderMemberSettingsMutationHookResult = ReturnType<typeof useUpdateProductProviderMemberSettingsMutation>;
export type UpdateProductProviderMemberSettingsMutationResult = Apollo.MutationResult<UpdateProductProviderMemberSettingsMutation>;
export type UpdateProductProviderMemberSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProductProviderMemberSettingsMutation, UpdateProductProviderMemberSettingsMutationVariables>;
export const AnalyticsProductReviewsDashboardDocument = gql`
    query AnalyticsProductReviewsDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $productIds: [ID!]!) {
  productReviewsDashboard(
    startOn: $startOn
    endOn: $endOn
    productIds: $productIds
  ) {
    url
  }
}
    `;

/**
 * __useAnalyticsProductReviewsDashboardQuery__
 *
 * To run a query within a React component, call `useAnalyticsProductReviewsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsProductReviewsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsProductReviewsDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useAnalyticsProductReviewsDashboardQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsProductReviewsDashboardQuery, AnalyticsProductReviewsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsProductReviewsDashboardQuery, AnalyticsProductReviewsDashboardQueryVariables>(AnalyticsProductReviewsDashboardDocument, options);
      }
export function useAnalyticsProductReviewsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsProductReviewsDashboardQuery, AnalyticsProductReviewsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsProductReviewsDashboardQuery, AnalyticsProductReviewsDashboardQueryVariables>(AnalyticsProductReviewsDashboardDocument, options);
        }
export type AnalyticsProductReviewsDashboardQueryHookResult = ReturnType<typeof useAnalyticsProductReviewsDashboardQuery>;
export type AnalyticsProductReviewsDashboardLazyQueryHookResult = ReturnType<typeof useAnalyticsProductReviewsDashboardLazyQuery>;
export type AnalyticsProductReviewsDashboardQueryResult = Apollo.QueryResult<AnalyticsProductReviewsDashboardQuery, AnalyticsProductReviewsDashboardQueryVariables>;
export const AnalyticsProductReviewsPageDocument = gql`
    query AnalyticsProductReviewsPage {
  products: products2 {
    id
    name: nameJa
  }
}
    `;

/**
 * __useAnalyticsProductReviewsPageQuery__
 *
 * To run a query within a React component, call `useAnalyticsProductReviewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsProductReviewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsProductReviewsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsProductReviewsPageQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsProductReviewsPageQuery, AnalyticsProductReviewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsProductReviewsPageQuery, AnalyticsProductReviewsPageQueryVariables>(AnalyticsProductReviewsPageDocument, options);
      }
export function useAnalyticsProductReviewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsProductReviewsPageQuery, AnalyticsProductReviewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsProductReviewsPageQuery, AnalyticsProductReviewsPageQueryVariables>(AnalyticsProductReviewsPageDocument, options);
        }
export type AnalyticsProductReviewsPageQueryHookResult = ReturnType<typeof useAnalyticsProductReviewsPageQuery>;
export type AnalyticsProductReviewsPageLazyQueryHookResult = ReturnType<typeof useAnalyticsProductReviewsPageLazyQuery>;
export type AnalyticsProductReviewsPageQueryResult = Apollo.QueryResult<AnalyticsProductReviewsPageQuery, AnalyticsProductReviewsPageQueryVariables>;
export const AnalyticsTicketReviewsDashboardDocument = gql`
    query AnalyticsTicketReviewsDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $ticketIds: [ID!]!) {
  ticketReviewsDashboard(startOn: $startOn, endOn: $endOn, ticketIds: $ticketIds) {
    url
  }
}
    `;

/**
 * __useAnalyticsTicketReviewsDashboardQuery__
 *
 * To run a query within a React component, call `useAnalyticsTicketReviewsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTicketReviewsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTicketReviewsDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      ticketIds: // value for 'ticketIds'
 *   },
 * });
 */
export function useAnalyticsTicketReviewsDashboardQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsTicketReviewsDashboardQuery, AnalyticsTicketReviewsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsTicketReviewsDashboardQuery, AnalyticsTicketReviewsDashboardQueryVariables>(AnalyticsTicketReviewsDashboardDocument, options);
      }
export function useAnalyticsTicketReviewsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsTicketReviewsDashboardQuery, AnalyticsTicketReviewsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsTicketReviewsDashboardQuery, AnalyticsTicketReviewsDashboardQueryVariables>(AnalyticsTicketReviewsDashboardDocument, options);
        }
export type AnalyticsTicketReviewsDashboardQueryHookResult = ReturnType<typeof useAnalyticsTicketReviewsDashboardQuery>;
export type AnalyticsTicketReviewsDashboardLazyQueryHookResult = ReturnType<typeof useAnalyticsTicketReviewsDashboardLazyQuery>;
export type AnalyticsTicketReviewsDashboardQueryResult = Apollo.QueryResult<AnalyticsTicketReviewsDashboardQuery, AnalyticsTicketReviewsDashboardQueryVariables>;
export const AnalyticsTicketReviewsPageDocument = gql`
    query AnalyticsTicketReviewsPage {
  tickets: tickets3 {
    id
    name: nameJa
    product {
      id
      name: nameJa
    }
  }
}
    `;

/**
 * __useAnalyticsTicketReviewsPageQuery__
 *
 * To run a query within a React component, call `useAnalyticsTicketReviewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTicketReviewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTicketReviewsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsTicketReviewsPageQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsTicketReviewsPageQuery, AnalyticsTicketReviewsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsTicketReviewsPageQuery, AnalyticsTicketReviewsPageQueryVariables>(AnalyticsTicketReviewsPageDocument, options);
      }
export function useAnalyticsTicketReviewsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsTicketReviewsPageQuery, AnalyticsTicketReviewsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsTicketReviewsPageQuery, AnalyticsTicketReviewsPageQueryVariables>(AnalyticsTicketReviewsPageDocument, options);
        }
export type AnalyticsTicketReviewsPageQueryHookResult = ReturnType<typeof useAnalyticsTicketReviewsPageQuery>;
export type AnalyticsTicketReviewsPageLazyQueryHookResult = ReturnType<typeof useAnalyticsTicketReviewsPageLazyQuery>;
export type AnalyticsTicketReviewsPageQueryResult = Apollo.QueryResult<AnalyticsTicketReviewsPageQuery, AnalyticsTicketReviewsPageQueryVariables>;
export const AnalyticsTicketUsagesDashboardDocument = gql`
    query AnalyticsTicketUsagesDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $ticketIds: [ID!]!) {
  ticketUsagesDashboard(startOn: $startOn, endOn: $endOn, ticketIds: $ticketIds) {
    url
  }
}
    `;

/**
 * __useAnalyticsTicketUsagesDashboardQuery__
 *
 * To run a query within a React component, call `useAnalyticsTicketUsagesDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTicketUsagesDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTicketUsagesDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      ticketIds: // value for 'ticketIds'
 *   },
 * });
 */
export function useAnalyticsTicketUsagesDashboardQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsTicketUsagesDashboardQuery, AnalyticsTicketUsagesDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsTicketUsagesDashboardQuery, AnalyticsTicketUsagesDashboardQueryVariables>(AnalyticsTicketUsagesDashboardDocument, options);
      }
export function useAnalyticsTicketUsagesDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsTicketUsagesDashboardQuery, AnalyticsTicketUsagesDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsTicketUsagesDashboardQuery, AnalyticsTicketUsagesDashboardQueryVariables>(AnalyticsTicketUsagesDashboardDocument, options);
        }
export type AnalyticsTicketUsagesDashboardQueryHookResult = ReturnType<typeof useAnalyticsTicketUsagesDashboardQuery>;
export type AnalyticsTicketUsagesDashboardLazyQueryHookResult = ReturnType<typeof useAnalyticsTicketUsagesDashboardLazyQuery>;
export type AnalyticsTicketUsagesDashboardQueryResult = Apollo.QueryResult<AnalyticsTicketUsagesDashboardQuery, AnalyticsTicketUsagesDashboardQueryVariables>;
export const AnalyticsTicketUsagesPageDocument = gql`
    query AnalyticsTicketUsagesPage {
  tickets: tickets3 {
    id
    name: nameJa
    product {
      id
      name: nameJa
    }
  }
}
    `;

/**
 * __useAnalyticsTicketUsagesPageQuery__
 *
 * To run a query within a React component, call `useAnalyticsTicketUsagesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsTicketUsagesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsTicketUsagesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsTicketUsagesPageQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsTicketUsagesPageQuery, AnalyticsTicketUsagesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsTicketUsagesPageQuery, AnalyticsTicketUsagesPageQueryVariables>(AnalyticsTicketUsagesPageDocument, options);
      }
export function useAnalyticsTicketUsagesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsTicketUsagesPageQuery, AnalyticsTicketUsagesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsTicketUsagesPageQuery, AnalyticsTicketUsagesPageQueryVariables>(AnalyticsTicketUsagesPageDocument, options);
        }
export type AnalyticsTicketUsagesPageQueryHookResult = ReturnType<typeof useAnalyticsTicketUsagesPageQuery>;
export type AnalyticsTicketUsagesPageLazyQueryHookResult = ReturnType<typeof useAnalyticsTicketUsagesPageLazyQuery>;
export type AnalyticsTicketUsagesPageQueryResult = Apollo.QueryResult<AnalyticsTicketUsagesPageQuery, AnalyticsTicketUsagesPageQueryVariables>;
export const AnalyticsUserDemographicsDashboardDocument = gql`
    query AnalyticsUserDemographicsDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $productIds: [ID!]!) {
  userDemographicsDashboard(
    startOn: $startOn
    endOn: $endOn
    productIds: $productIds
  ) {
    url
  }
}
    `;

/**
 * __useAnalyticsUserDemographicsDashboardQuery__
 *
 * To run a query within a React component, call `useAnalyticsUserDemographicsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUserDemographicsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUserDemographicsDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useAnalyticsUserDemographicsDashboardQuery(baseOptions: Apollo.QueryHookOptions<AnalyticsUserDemographicsDashboardQuery, AnalyticsUserDemographicsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUserDemographicsDashboardQuery, AnalyticsUserDemographicsDashboardQueryVariables>(AnalyticsUserDemographicsDashboardDocument, options);
      }
export function useAnalyticsUserDemographicsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUserDemographicsDashboardQuery, AnalyticsUserDemographicsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUserDemographicsDashboardQuery, AnalyticsUserDemographicsDashboardQueryVariables>(AnalyticsUserDemographicsDashboardDocument, options);
        }
export type AnalyticsUserDemographicsDashboardQueryHookResult = ReturnType<typeof useAnalyticsUserDemographicsDashboardQuery>;
export type AnalyticsUserDemographicsDashboardLazyQueryHookResult = ReturnType<typeof useAnalyticsUserDemographicsDashboardLazyQuery>;
export type AnalyticsUserDemographicsDashboardQueryResult = Apollo.QueryResult<AnalyticsUserDemographicsDashboardQuery, AnalyticsUserDemographicsDashboardQueryVariables>;
export const AnalyticsUserDemographicsPageDocument = gql`
    query AnalyticsUserDemographicsPage {
  products: products2 {
    id
    name: nameJa
  }
}
    `;

/**
 * __useAnalyticsUserDemographicsPageQuery__
 *
 * To run a query within a React component, call `useAnalyticsUserDemographicsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsUserDemographicsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsUserDemographicsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsUserDemographicsPageQuery(baseOptions?: Apollo.QueryHookOptions<AnalyticsUserDemographicsPageQuery, AnalyticsUserDemographicsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyticsUserDemographicsPageQuery, AnalyticsUserDemographicsPageQueryVariables>(AnalyticsUserDemographicsPageDocument, options);
      }
export function useAnalyticsUserDemographicsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyticsUserDemographicsPageQuery, AnalyticsUserDemographicsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyticsUserDemographicsPageQuery, AnalyticsUserDemographicsPageQueryVariables>(AnalyticsUserDemographicsPageDocument, options);
        }
export type AnalyticsUserDemographicsPageQueryHookResult = ReturnType<typeof useAnalyticsUserDemographicsPageQuery>;
export type AnalyticsUserDemographicsPageLazyQueryHookResult = ReturnType<typeof useAnalyticsUserDemographicsPageLazyQuery>;
export type AnalyticsUserDemographicsPageQueryResult = Apollo.QueryResult<AnalyticsUserDemographicsPageQuery, AnalyticsUserDemographicsPageQueryVariables>;
export const IndexPageDocument = gql`
    query IndexPage($thisMonthFrom: ISO8601DateTime!, $thisMonthTo: ISO8601DateTime!, $todayFrom: ISO8601DateTime!, $todayTo: ISO8601DateTime!) {
  thisMonthProductSales: productSales(from: $thisMonthFrom, to: $thisMonthTo) {
    ...ProductSalesFields
  }
  todayProductSales: productSales(from: $todayFrom, to: $todayTo) {
    ...ProductSalesFields
  }
  productProviderMember {
    ...ProductProviderMemberFields
  }
}
    ${ProductSalesFieldsFragmentDoc}
${ProductProviderMemberFieldsFragmentDoc}`;

/**
 * __useIndexPageQuery__
 *
 * To run a query within a React component, call `useIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexPageQuery({
 *   variables: {
 *      thisMonthFrom: // value for 'thisMonthFrom'
 *      thisMonthTo: // value for 'thisMonthTo'
 *      todayFrom: // value for 'todayFrom'
 *      todayTo: // value for 'todayTo'
 *   },
 * });
 */
export function useIndexPageQuery(baseOptions: Apollo.QueryHookOptions<IndexPageQuery, IndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndexPageQuery, IndexPageQueryVariables>(IndexPageDocument, options);
      }
export function useIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexPageQuery, IndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndexPageQuery, IndexPageQueryVariables>(IndexPageDocument, options);
        }
export type IndexPageQueryHookResult = ReturnType<typeof useIndexPageQuery>;
export type IndexPageLazyQueryHookResult = ReturnType<typeof useIndexPageLazyQuery>;
export type IndexPageQueryResult = Apollo.QueryResult<IndexPageQuery, IndexPageQueryVariables>;
export const PlaceCategoriesDocument = gql`
    query PlaceCategories {
  placeCategories {
    ...PlaceCategoryFields
  }
}
    ${PlaceCategoryFieldsFragmentDoc}`;

/**
 * __usePlaceCategoriesQuery__
 *
 * To run a query within a React component, call `usePlaceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<PlaceCategoriesQuery, PlaceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceCategoriesQuery, PlaceCategoriesQueryVariables>(PlaceCategoriesDocument, options);
      }
export function usePlaceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceCategoriesQuery, PlaceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceCategoriesQuery, PlaceCategoriesQueryVariables>(PlaceCategoriesDocument, options);
        }
export type PlaceCategoriesQueryHookResult = ReturnType<typeof usePlaceCategoriesQuery>;
export type PlaceCategoriesLazyQueryHookResult = ReturnType<typeof usePlaceCategoriesLazyQuery>;
export type PlaceCategoriesQueryResult = Apollo.QueryResult<PlaceCategoriesQuery, PlaceCategoriesQueryVariables>;
export const PlaceEditPageDocument = gql`
    query PlaceEditPage($id: ID!) {
  place(id: $id) {
    ...PlaceFields
    placeCategory {
      ...PlaceCategoryFields
    }
    coverImages {
      ...PlaceCoverImageFields
    }
    contentImages {
      ...PlaceContentImageFields
    }
    placeTaggings {
      placeTag {
        ...PlaceTagFields
      }
    }
    placeExternalLinks {
      ...PlaceExternalLinkFields
    }
    placeSpeccings {
      ...PlaceSpeccingFields
      placeSpec {
        ...PlaceSpecFields
      }
    }
    vacanTargetKey {
      ...VacanTargetKeyFields
    }
  }
  placeCategories {
    ...PlaceCategoryFields
  }
  placeTagLists(size: 1000, page: 1, orderType: "", sortType: "") {
    ...PlaceTagFields
  }
  placeSpecLists(orderType: "", sortType: "") {
    ...PlaceSpecFields
  }
}
    ${PlaceFieldsFragmentDoc}
${PlaceCategoryFieldsFragmentDoc}
${PlaceCoverImageFieldsFragmentDoc}
${PlaceContentImageFieldsFragmentDoc}
${PlaceTagFieldsFragmentDoc}
${PlaceExternalLinkFieldsFragmentDoc}
${PlaceSpeccingFieldsFragmentDoc}
${PlaceSpecFieldsFragmentDoc}
${VacanTargetKeyFieldsFragmentDoc}`;

/**
 * __usePlaceEditPageQuery__
 *
 * To run a query within a React component, call `usePlaceEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceEditPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaceEditPageQuery(baseOptions: Apollo.QueryHookOptions<PlaceEditPageQuery, PlaceEditPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceEditPageQuery, PlaceEditPageQueryVariables>(PlaceEditPageDocument, options);
      }
export function usePlaceEditPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceEditPageQuery, PlaceEditPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceEditPageQuery, PlaceEditPageQueryVariables>(PlaceEditPageDocument, options);
        }
export type PlaceEditPageQueryHookResult = ReturnType<typeof usePlaceEditPageQuery>;
export type PlaceEditPageLazyQueryHookResult = ReturnType<typeof usePlaceEditPageLazyQuery>;
export type PlaceEditPageQueryResult = Apollo.QueryResult<PlaceEditPageQuery, PlaceEditPageQueryVariables>;
export const PlaceSpecDetailDocument = gql`
    query PlaceSpecDetail($id: ID!) {
  placeSpec(id: $id) {
    id
    nameJa
  }
}
    `;

/**
 * __usePlaceSpecDetailQuery__
 *
 * To run a query within a React component, call `usePlaceSpecDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceSpecDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceSpecDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaceSpecDetailQuery(baseOptions: Apollo.QueryHookOptions<PlaceSpecDetailQuery, PlaceSpecDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceSpecDetailQuery, PlaceSpecDetailQueryVariables>(PlaceSpecDetailDocument, options);
      }
export function usePlaceSpecDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceSpecDetailQuery, PlaceSpecDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceSpecDetailQuery, PlaceSpecDetailQueryVariables>(PlaceSpecDetailDocument, options);
        }
export type PlaceSpecDetailQueryHookResult = ReturnType<typeof usePlaceSpecDetailQuery>;
export type PlaceSpecDetailLazyQueryHookResult = ReturnType<typeof usePlaceSpecDetailLazyQuery>;
export type PlaceSpecDetailQueryResult = Apollo.QueryResult<PlaceSpecDetailQuery, PlaceSpecDetailQueryVariables>;
export const PlaceSpecsPageDocument = gql`
    query PlaceSpecsPage($orderType: String!, $sortType: String!) {
  placeSpecLists(orderType: $orderType, sortType: $sortType) {
    id
    nameJa
  }
}
    `;

/**
 * __usePlaceSpecsPageQuery__
 *
 * To run a query within a React component, call `usePlaceSpecsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceSpecsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceSpecsPageQuery({
 *   variables: {
 *      orderType: // value for 'orderType'
 *      sortType: // value for 'sortType'
 *   },
 * });
 */
export function usePlaceSpecsPageQuery(baseOptions: Apollo.QueryHookOptions<PlaceSpecsPageQuery, PlaceSpecsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceSpecsPageQuery, PlaceSpecsPageQueryVariables>(PlaceSpecsPageDocument, options);
      }
export function usePlaceSpecsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceSpecsPageQuery, PlaceSpecsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceSpecsPageQuery, PlaceSpecsPageQueryVariables>(PlaceSpecsPageDocument, options);
        }
export type PlaceSpecsPageQueryHookResult = ReturnType<typeof usePlaceSpecsPageQuery>;
export type PlaceSpecsPageLazyQueryHookResult = ReturnType<typeof usePlaceSpecsPageLazyQuery>;
export type PlaceSpecsPageQueryResult = Apollo.QueryResult<PlaceSpecsPageQuery, PlaceSpecsPageQueryVariables>;
export const PlaceTagDetailDocument = gql`
    query PlaceTagDetail($id: ID!) {
  placeTag(id: $id) {
    id
    nameJa
  }
}
    `;

/**
 * __usePlaceTagDetailQuery__
 *
 * To run a query within a React component, call `usePlaceTagDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceTagDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceTagDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaceTagDetailQuery(baseOptions: Apollo.QueryHookOptions<PlaceTagDetailQuery, PlaceTagDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceTagDetailQuery, PlaceTagDetailQueryVariables>(PlaceTagDetailDocument, options);
      }
export function usePlaceTagDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceTagDetailQuery, PlaceTagDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceTagDetailQuery, PlaceTagDetailQueryVariables>(PlaceTagDetailDocument, options);
        }
export type PlaceTagDetailQueryHookResult = ReturnType<typeof usePlaceTagDetailQuery>;
export type PlaceTagDetailLazyQueryHookResult = ReturnType<typeof usePlaceTagDetailLazyQuery>;
export type PlaceTagDetailQueryResult = Apollo.QueryResult<PlaceTagDetailQuery, PlaceTagDetailQueryVariables>;
export const PlaceTagsPageDocument = gql`
    query PlaceTagsPage($page: Int!, $size: Int!, $sortType: String!, $orderType: String!) {
  placeTagLists(
    page: $page
    size: $size
    sortType: $sortType
    orderType: $orderType
  ) {
    id
    nameJa
  }
  placesTagTotal
}
    `;

/**
 * __usePlaceTagsPageQuery__
 *
 * To run a query within a React component, call `usePlaceTagsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceTagsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceTagsPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      sortType: // value for 'sortType'
 *      orderType: // value for 'orderType'
 *   },
 * });
 */
export function usePlaceTagsPageQuery(baseOptions: Apollo.QueryHookOptions<PlaceTagsPageQuery, PlaceTagsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaceTagsPageQuery, PlaceTagsPageQueryVariables>(PlaceTagsPageDocument, options);
      }
export function usePlaceTagsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaceTagsPageQuery, PlaceTagsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaceTagsPageQuery, PlaceTagsPageQueryVariables>(PlaceTagsPageDocument, options);
        }
export type PlaceTagsPageQueryHookResult = ReturnType<typeof usePlaceTagsPageQuery>;
export type PlaceTagsPageLazyQueryHookResult = ReturnType<typeof usePlaceTagsPageLazyQuery>;
export type PlaceTagsPageQueryResult = Apollo.QueryResult<PlaceTagsPageQuery, PlaceTagsPageQueryVariables>;
export const PlacesPageDocument = gql`
    query PlacesPage($page: Int!, $size: Int!, $searchKey: String!, $placeCategoryIds: [ID!]) {
  places(
    page: $page
    size: $size
    searchKey: $searchKey
    placeCategoryIds: $placeCategoryIds
  ) {
    ...PlaceFields
    coverImages(limit: 1) {
      ...PlaceCoverImageFields
    }
    placeCategory {
      ...PlaceCategoryFields
    }
  }
  placesTotal
  placeCategories {
    ...PlaceCategoryFields
  }
}
    ${PlaceFieldsFragmentDoc}
${PlaceCoverImageFieldsFragmentDoc}
${PlaceCategoryFieldsFragmentDoc}`;

/**
 * __usePlacesPageQuery__
 *
 * To run a query within a React component, call `usePlacesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      searchKey: // value for 'searchKey'
 *      placeCategoryIds: // value for 'placeCategoryIds'
 *   },
 * });
 */
export function usePlacesPageQuery(baseOptions: Apollo.QueryHookOptions<PlacesPageQuery, PlacesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacesPageQuery, PlacesPageQueryVariables>(PlacesPageDocument, options);
      }
export function usePlacesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesPageQuery, PlacesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacesPageQuery, PlacesPageQueryVariables>(PlacesPageDocument, options);
        }
export type PlacesPageQueryHookResult = ReturnType<typeof usePlacesPageQuery>;
export type PlacesPageLazyQueryHookResult = ReturnType<typeof usePlacesPageLazyQuery>;
export type PlacesPageQueryResult = Apollo.QueryResult<PlacesPageQuery, PlacesPageQueryVariables>;
export const ProductEditPageDocument = gql`
    query ProductEditPage($id: ID!) {
  product: product2(id: $id) {
    ...ProductFields
    prefecture {
      slug
      name
    }
    contentImages {
      ...ProductContentImageFields
    }
    coverImages {
      ...ProductCoverImageFields
    }
    prices {
      ...ProductPriceFields
    }
    unavailableDays
    productAvailableDaysOverrideRules {
      ...ProductAvailableDaysOverrideRuleFields
    }
    tickets {
      ...TicketFields
      coverImages {
        ...TicketCoverImageFields
      }
      spots {
        ...SpotFields
        coverImages {
          ...SpotCoverImageFields
        }
      }
      ticketUsageOptions {
        ...TicketUsageOptionFields
      }
      ticketCategory {
        ...TicketCategoryFields
      }
    }
  }
  ticketCategories {
    ...TicketCategoryFields
  }
  prefectures {
    slug
    name
  }
  holidays {
    year
    days {
      name
      date
    }
  }
}
    ${ProductFieldsFragmentDoc}
${ProductContentImageFieldsFragmentDoc}
${ProductCoverImageFieldsFragmentDoc}
${ProductPriceFieldsFragmentDoc}
${ProductAvailableDaysOverrideRuleFieldsFragmentDoc}
${TicketFieldsFragmentDoc}
${TicketCoverImageFieldsFragmentDoc}
${SpotFieldsFragmentDoc}
${SpotCoverImageFieldsFragmentDoc}
${TicketUsageOptionFieldsFragmentDoc}
${TicketCategoryFieldsFragmentDoc}`;

/**
 * __useProductEditPageQuery__
 *
 * To run a query within a React component, call `useProductEditPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductEditPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductEditPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductEditPageQuery(baseOptions: Apollo.QueryHookOptions<ProductEditPageQuery, ProductEditPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductEditPageQuery, ProductEditPageQueryVariables>(ProductEditPageDocument, options);
      }
export function useProductEditPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductEditPageQuery, ProductEditPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductEditPageQuery, ProductEditPageQueryVariables>(ProductEditPageDocument, options);
        }
export type ProductEditPageQueryHookResult = ReturnType<typeof useProductEditPageQuery>;
export type ProductEditPageLazyQueryHookResult = ReturnType<typeof useProductEditPageLazyQuery>;
export type ProductEditPageQueryResult = Apollo.QueryResult<ProductEditPageQuery, ProductEditPageQueryVariables>;
export const ProductProviderMemberDocument = gql`
    query ProductProviderMember {
  productProviderMember {
    ...ProductProviderMemberFields
  }
}
    ${ProductProviderMemberFieldsFragmentDoc}`;

/**
 * __useProductProviderMemberQuery__
 *
 * To run a query within a React component, call `useProductProviderMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductProviderMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductProviderMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductProviderMemberQuery(baseOptions?: Apollo.QueryHookOptions<ProductProviderMemberQuery, ProductProviderMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductProviderMemberQuery, ProductProviderMemberQueryVariables>(ProductProviderMemberDocument, options);
      }
export function useProductProviderMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductProviderMemberQuery, ProductProviderMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductProviderMemberQuery, ProductProviderMemberQueryVariables>(ProductProviderMemberDocument, options);
        }
export type ProductProviderMemberQueryHookResult = ReturnType<typeof useProductProviderMemberQuery>;
export type ProductProviderMemberLazyQueryHookResult = ReturnType<typeof useProductProviderMemberLazyQuery>;
export type ProductProviderMemberQueryResult = Apollo.QueryResult<ProductProviderMemberQuery, ProductProviderMemberQueryVariables>;
export const ProductsPageDocument = gql`
    query ProductsPage {
  products: products2 {
    ...ProductFields
    coverImages(limit: 1) {
      ...ProductCoverImageFields
    }
  }
}
    ${ProductFieldsFragmentDoc}
${ProductCoverImageFieldsFragmentDoc}`;

/**
 * __useProductsPageQuery__
 *
 * To run a query within a React component, call `useProductsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsPageQuery(baseOptions?: Apollo.QueryHookOptions<ProductsPageQuery, ProductsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsPageQuery, ProductsPageQueryVariables>(ProductsPageDocument, options);
      }
export function useProductsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsPageQuery, ProductsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsPageQuery, ProductsPageQueryVariables>(ProductsPageDocument, options);
        }
export type ProductsPageQueryHookResult = ReturnType<typeof useProductsPageQuery>;
export type ProductsPageLazyQueryHookResult = ReturnType<typeof useProductsPageLazyQuery>;
export type ProductsPageQueryResult = Apollo.QueryResult<ProductsPageQuery, ProductsPageQueryVariables>;
export const PurchasedProductsIndexPageDocument = gql`
    query PurchasedProductsIndexPage($from: ISO8601DateTime!, $to: ISO8601DateTime!) {
  purchasedProductListItems(from: $from, to: $to) {
    id
    usageStartAt
    productId
    productName
    subtotal
    commissionFee
    quantity
    quantityDetails
    subtotal
  }
  productProvider {
    ...ProductProviderFields
  }
}
    ${ProductProviderFieldsFragmentDoc}`;

/**
 * __usePurchasedProductsIndexPageQuery__
 *
 * To run a query within a React component, call `usePurchasedProductsIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasedProductsIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasedProductsIndexPageQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePurchasedProductsIndexPageQuery(baseOptions: Apollo.QueryHookOptions<PurchasedProductsIndexPageQuery, PurchasedProductsIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchasedProductsIndexPageQuery, PurchasedProductsIndexPageQueryVariables>(PurchasedProductsIndexPageDocument, options);
      }
export function usePurchasedProductsIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasedProductsIndexPageQuery, PurchasedProductsIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchasedProductsIndexPageQuery, PurchasedProductsIndexPageQueryVariables>(PurchasedProductsIndexPageDocument, options);
        }
export type PurchasedProductsIndexPageQueryHookResult = ReturnType<typeof usePurchasedProductsIndexPageQuery>;
export type PurchasedProductsIndexPageLazyQueryHookResult = ReturnType<typeof usePurchasedProductsIndexPageLazyQuery>;
export type PurchasedProductsIndexPageQueryResult = Apollo.QueryResult<PurchasedProductsIndexPageQuery, PurchasedProductsIndexPageQueryVariables>;
export const PushNotificationIndexPageDocument = gql`
    query PushNotificationIndexPage {
  productProvider {
    ...ProductProviderFields
  }
}
    ${ProductProviderFieldsFragmentDoc}`;

/**
 * __usePushNotificationIndexPageQuery__
 *
 * To run a query within a React component, call `usePushNotificationIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushNotificationIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePushNotificationIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<PushNotificationIndexPageQuery, PushNotificationIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PushNotificationIndexPageQuery, PushNotificationIndexPageQueryVariables>(PushNotificationIndexPageDocument, options);
      }
export function usePushNotificationIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PushNotificationIndexPageQuery, PushNotificationIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PushNotificationIndexPageQuery, PushNotificationIndexPageQueryVariables>(PushNotificationIndexPageDocument, options);
        }
export type PushNotificationIndexPageQueryHookResult = ReturnType<typeof usePushNotificationIndexPageQuery>;
export type PushNotificationIndexPageLazyQueryHookResult = ReturnType<typeof usePushNotificationIndexPageLazyQuery>;
export type PushNotificationIndexPageQueryResult = Apollo.QueryResult<PushNotificationIndexPageQuery, PushNotificationIndexPageQueryVariables>;
export const RightContentDocument = gql`
    query RightContent {
  productProvider {
    ...ProductProviderFields
  }
}
    ${ProductProviderFieldsFragmentDoc}`;

/**
 * __useRightContentQuery__
 *
 * To run a query within a React component, call `useRightContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRightContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRightContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useRightContentQuery(baseOptions?: Apollo.QueryHookOptions<RightContentQuery, RightContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RightContentQuery, RightContentQueryVariables>(RightContentDocument, options);
      }
export function useRightContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RightContentQuery, RightContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RightContentQuery, RightContentQueryVariables>(RightContentDocument, options);
        }
export type RightContentQueryHookResult = ReturnType<typeof useRightContentQuery>;
export type RightContentLazyQueryHookResult = ReturnType<typeof useRightContentLazyQuery>;
export type RightContentQueryResult = Apollo.QueryResult<RightContentQuery, RightContentQueryVariables>;
export const SalesDailyProductSalesIndexPageDocument = gql`
    query SalesDailyProductSalesIndexPage($year: Int!, $month: Int!) {
  dailyProductSales(year: $year, month: $month) {
    date
    productId
    productName
    productPriceId
    productPriceName
    quantity
    subtotal
  }
  productProvider {
    ...ProductProviderFields
  }
}
    ${ProductProviderFieldsFragmentDoc}`;

/**
 * __useSalesDailyProductSalesIndexPageQuery__
 *
 * To run a query within a React component, call `useSalesDailyProductSalesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDailyProductSalesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesDailyProductSalesIndexPageQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useSalesDailyProductSalesIndexPageQuery(baseOptions: Apollo.QueryHookOptions<SalesDailyProductSalesIndexPageQuery, SalesDailyProductSalesIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesDailyProductSalesIndexPageQuery, SalesDailyProductSalesIndexPageQueryVariables>(SalesDailyProductSalesIndexPageDocument, options);
      }
export function useSalesDailyProductSalesIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesDailyProductSalesIndexPageQuery, SalesDailyProductSalesIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesDailyProductSalesIndexPageQuery, SalesDailyProductSalesIndexPageQueryVariables>(SalesDailyProductSalesIndexPageDocument, options);
        }
export type SalesDailyProductSalesIndexPageQueryHookResult = ReturnType<typeof useSalesDailyProductSalesIndexPageQuery>;
export type SalesDailyProductSalesIndexPageLazyQueryHookResult = ReturnType<typeof useSalesDailyProductSalesIndexPageLazyQuery>;
export type SalesDailyProductSalesIndexPageQueryResult = Apollo.QueryResult<SalesDailyProductSalesIndexPageQuery, SalesDailyProductSalesIndexPageQueryVariables>;
export const SalesDailyTicketUsagesIndexPageDocument = gql`
    query SalesDailyTicketUsagesIndexPage($year: Int!, $month: Int!) {
  dailyTicketUsages(year: $year, month: $month) {
    date
    productId
    productName
    ticketId
    ticketName
    quantity
  }
  productProvider {
    ...ProductProviderFields
  }
}
    ${ProductProviderFieldsFragmentDoc}`;

/**
 * __useSalesDailyTicketUsagesIndexPageQuery__
 *
 * To run a query within a React component, call `useSalesDailyTicketUsagesIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesDailyTicketUsagesIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesDailyTicketUsagesIndexPageQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useSalesDailyTicketUsagesIndexPageQuery(baseOptions: Apollo.QueryHookOptions<SalesDailyTicketUsagesIndexPageQuery, SalesDailyTicketUsagesIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesDailyTicketUsagesIndexPageQuery, SalesDailyTicketUsagesIndexPageQueryVariables>(SalesDailyTicketUsagesIndexPageDocument, options);
      }
export function useSalesDailyTicketUsagesIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesDailyTicketUsagesIndexPageQuery, SalesDailyTicketUsagesIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesDailyTicketUsagesIndexPageQuery, SalesDailyTicketUsagesIndexPageQueryVariables>(SalesDailyTicketUsagesIndexPageDocument, options);
        }
export type SalesDailyTicketUsagesIndexPageQueryHookResult = ReturnType<typeof useSalesDailyTicketUsagesIndexPageQuery>;
export type SalesDailyTicketUsagesIndexPageLazyQueryHookResult = ReturnType<typeof useSalesDailyTicketUsagesIndexPageLazyQuery>;
export type SalesDailyTicketUsagesIndexPageQueryResult = Apollo.QueryResult<SalesDailyTicketUsagesIndexPageQuery, SalesDailyTicketUsagesIndexPageQueryVariables>;
export const SalesSummaryIndexPageDocument = gql`
    query SalesSummaryIndexPage($from: ISO8601DateTime!, $to: ISO8601DateTime!) {
  productSales(from: $from, to: $to) {
    ...ProductSalesFields
  }
}
    ${ProductSalesFieldsFragmentDoc}`;

/**
 * __useSalesSummaryIndexPageQuery__
 *
 * To run a query within a React component, call `useSalesSummaryIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesSummaryIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesSummaryIndexPageQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSalesSummaryIndexPageQuery(baseOptions: Apollo.QueryHookOptions<SalesSummaryIndexPageQuery, SalesSummaryIndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesSummaryIndexPageQuery, SalesSummaryIndexPageQueryVariables>(SalesSummaryIndexPageDocument, options);
      }
export function useSalesSummaryIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesSummaryIndexPageQuery, SalesSummaryIndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesSummaryIndexPageQuery, SalesSummaryIndexPageQueryVariables>(SalesSummaryIndexPageDocument, options);
        }
export type SalesSummaryIndexPageQueryHookResult = ReturnType<typeof useSalesSummaryIndexPageQuery>;
export type SalesSummaryIndexPageLazyQueryHookResult = ReturnType<typeof useSalesSummaryIndexPageLazyQuery>;
export type SalesSummaryIndexPageQueryResult = Apollo.QueryResult<SalesSummaryIndexPageQuery, SalesSummaryIndexPageQueryVariables>;
export const SimplePassMyPassDetailDocument = gql`
    query SimplePassMyPassDetail($id: ID!) {
  simplePassMyPass(id: $id) {
    ...SimplePassMyPassFields
    maxMyCertificateValidityEndOn
    originDestination {
      origin {
        ...SimplePassOriginFields
      }
      destination {
        ...SimplePassDestinationFields
      }
      via {
        ...SimplePassViaFields
      }
    }
    passPeriod {
      ...SimplePassPassPeriodFields
    }
    passType {
      ...SimplePassPassTypeFields
    }
    myCertificate {
      ...SimplePassMyCertificateFields
      myPasses {
        id
        statusName
        usageStartOn
        createdAt
        passPeriod {
          periodType
        }
      }
    }
  }
}
    ${SimplePassMyPassFieldsFragmentDoc}
${SimplePassOriginFieldsFragmentDoc}
${SimplePassDestinationFieldsFragmentDoc}
${SimplePassViaFieldsFragmentDoc}
${SimplePassPassPeriodFieldsFragmentDoc}
${SimplePassPassTypeFieldsFragmentDoc}
${SimplePassMyCertificateFieldsFragmentDoc}`;

/**
 * __useSimplePassMyPassDetailQuery__
 *
 * To run a query within a React component, call `useSimplePassMyPassDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassMyPassDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassMyPassDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimplePassMyPassDetailQuery(baseOptions: Apollo.QueryHookOptions<SimplePassMyPassDetailQuery, SimplePassMyPassDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassMyPassDetailQuery, SimplePassMyPassDetailQueryVariables>(SimplePassMyPassDetailDocument, options);
      }
export function useSimplePassMyPassDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassMyPassDetailQuery, SimplePassMyPassDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassMyPassDetailQuery, SimplePassMyPassDetailQueryVariables>(SimplePassMyPassDetailDocument, options);
        }
export type SimplePassMyPassDetailQueryHookResult = ReturnType<typeof useSimplePassMyPassDetailQuery>;
export type SimplePassMyPassDetailLazyQueryHookResult = ReturnType<typeof useSimplePassMyPassDetailLazyQuery>;
export type SimplePassMyPassDetailQueryResult = Apollo.QueryResult<SimplePassMyPassDetailQuery, SimplePassMyPassDetailQueryVariables>;
export const SimplePassSalesSummaryDashboardDocument = gql`
    query SimplePassSalesSummaryDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $passTypeIds: [ID!]!) {
  simplePassSalesSummaryDashboard(
    startOn: $startOn
    endOn: $endOn
    passTypeIds: $passTypeIds
  ) {
    url
  }
}
    `;

/**
 * __useSimplePassSalesSummaryDashboardQuery__
 *
 * To run a query within a React component, call `useSimplePassSalesSummaryDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassSalesSummaryDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassSalesSummaryDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      passTypeIds: // value for 'passTypeIds'
 *   },
 * });
 */
export function useSimplePassSalesSummaryDashboardQuery(baseOptions: Apollo.QueryHookOptions<SimplePassSalesSummaryDashboardQuery, SimplePassSalesSummaryDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassSalesSummaryDashboardQuery, SimplePassSalesSummaryDashboardQueryVariables>(SimplePassSalesSummaryDashboardDocument, options);
      }
export function useSimplePassSalesSummaryDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassSalesSummaryDashboardQuery, SimplePassSalesSummaryDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassSalesSummaryDashboardQuery, SimplePassSalesSummaryDashboardQueryVariables>(SimplePassSalesSummaryDashboardDocument, options);
        }
export type SimplePassSalesSummaryDashboardQueryHookResult = ReturnType<typeof useSimplePassSalesSummaryDashboardQuery>;
export type SimplePassSalesSummaryDashboardLazyQueryHookResult = ReturnType<typeof useSimplePassSalesSummaryDashboardLazyQuery>;
export type SimplePassSalesSummaryDashboardQueryResult = Apollo.QueryResult<SimplePassSalesSummaryDashboardQuery, SimplePassSalesSummaryDashboardQueryVariables>;
export const SimplePassSalesSummaryPageDocument = gql`
    query SimplePassSalesSummaryPage {
  simplePassPassTypes {
    id
    fullname
    passGroup {
      ...SimplePassPassGroupFields
    }
  }
}
    ${SimplePassPassGroupFieldsFragmentDoc}`;

/**
 * __useSimplePassSalesSummaryPageQuery__
 *
 * To run a query within a React component, call `useSimplePassSalesSummaryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassSalesSummaryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassSalesSummaryPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimplePassSalesSummaryPageQuery(baseOptions?: Apollo.QueryHookOptions<SimplePassSalesSummaryPageQuery, SimplePassSalesSummaryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassSalesSummaryPageQuery, SimplePassSalesSummaryPageQueryVariables>(SimplePassSalesSummaryPageDocument, options);
      }
export function useSimplePassSalesSummaryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassSalesSummaryPageQuery, SimplePassSalesSummaryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassSalesSummaryPageQuery, SimplePassSalesSummaryPageQueryVariables>(SimplePassSalesSummaryPageDocument, options);
        }
export type SimplePassSalesSummaryPageQueryHookResult = ReturnType<typeof useSimplePassSalesSummaryPageQuery>;
export type SimplePassSalesSummaryPageLazyQueryHookResult = ReturnType<typeof useSimplePassSalesSummaryPageLazyQuery>;
export type SimplePassSalesSummaryPageQueryResult = Apollo.QueryResult<SimplePassSalesSummaryPageQuery, SimplePassSalesSummaryPageQueryVariables>;
export const SimplePassUserDemographicsDashboardDocument = gql`
    query SimplePassUserDemographicsDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $passTypeIds: [ID!]!) {
  simplePassUserDemographicsDashboard(
    startOn: $startOn
    endOn: $endOn
    passTypeIds: $passTypeIds
  ) {
    url
  }
}
    `;

/**
 * __useSimplePassUserDemographicsDashboardQuery__
 *
 * To run a query within a React component, call `useSimplePassUserDemographicsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassUserDemographicsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassUserDemographicsDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      passTypeIds: // value for 'passTypeIds'
 *   },
 * });
 */
export function useSimplePassUserDemographicsDashboardQuery(baseOptions: Apollo.QueryHookOptions<SimplePassUserDemographicsDashboardQuery, SimplePassUserDemographicsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassUserDemographicsDashboardQuery, SimplePassUserDemographicsDashboardQueryVariables>(SimplePassUserDemographicsDashboardDocument, options);
      }
export function useSimplePassUserDemographicsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassUserDemographicsDashboardQuery, SimplePassUserDemographicsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassUserDemographicsDashboardQuery, SimplePassUserDemographicsDashboardQueryVariables>(SimplePassUserDemographicsDashboardDocument, options);
        }
export type SimplePassUserDemographicsDashboardQueryHookResult = ReturnType<typeof useSimplePassUserDemographicsDashboardQuery>;
export type SimplePassUserDemographicsDashboardLazyQueryHookResult = ReturnType<typeof useSimplePassUserDemographicsDashboardLazyQuery>;
export type SimplePassUserDemographicsDashboardQueryResult = Apollo.QueryResult<SimplePassUserDemographicsDashboardQuery, SimplePassUserDemographicsDashboardQueryVariables>;
export const SimplePassUserDemographicsPageDocument = gql`
    query SimplePassUserDemographicsPage {
  simplePassPassTypes {
    id
    fullname
    passGroup {
      ...SimplePassPassGroupFields
    }
  }
}
    ${SimplePassPassGroupFieldsFragmentDoc}`;

/**
 * __useSimplePassUserDemographicsPageQuery__
 *
 * To run a query within a React component, call `useSimplePassUserDemographicsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassUserDemographicsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassUserDemographicsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimplePassUserDemographicsPageQuery(baseOptions?: Apollo.QueryHookOptions<SimplePassUserDemographicsPageQuery, SimplePassUserDemographicsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassUserDemographicsPageQuery, SimplePassUserDemographicsPageQueryVariables>(SimplePassUserDemographicsPageDocument, options);
      }
export function useSimplePassUserDemographicsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassUserDemographicsPageQuery, SimplePassUserDemographicsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassUserDemographicsPageQuery, SimplePassUserDemographicsPageQueryVariables>(SimplePassUserDemographicsPageDocument, options);
        }
export type SimplePassUserDemographicsPageQueryHookResult = ReturnType<typeof useSimplePassUserDemographicsPageQuery>;
export type SimplePassUserDemographicsPageLazyQueryHookResult = ReturnType<typeof useSimplePassUserDemographicsPageLazyQuery>;
export type SimplePassUserDemographicsPageQueryResult = Apollo.QueryResult<SimplePassUserDemographicsPageQuery, SimplePassUserDemographicsPageQueryVariables>;
export const VacanPlaceIdHashPageDocument = gql`
    query VacanPlaceIdHashPage($id: ID!) {
  vacanPlaces(id: $id) {
    id
    name
    placeIdHash
    checked
  }
}
    `;

/**
 * __useVacanPlaceIdHashPageQuery__
 *
 * To run a query within a React component, call `useVacanPlaceIdHashPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanPlaceIdHashPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanPlaceIdHashPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVacanPlaceIdHashPageQuery(baseOptions: Apollo.QueryHookOptions<VacanPlaceIdHashPageQuery, VacanPlaceIdHashPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanPlaceIdHashPageQuery, VacanPlaceIdHashPageQueryVariables>(VacanPlaceIdHashPageDocument, options);
      }
export function useVacanPlaceIdHashPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanPlaceIdHashPageQuery, VacanPlaceIdHashPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanPlaceIdHashPageQuery, VacanPlaceIdHashPageQueryVariables>(VacanPlaceIdHashPageDocument, options);
        }
export type VacanPlaceIdHashPageQueryHookResult = ReturnType<typeof useVacanPlaceIdHashPageQuery>;
export type VacanPlaceIdHashPageLazyQueryHookResult = ReturnType<typeof useVacanPlaceIdHashPageLazyQuery>;
export type VacanPlaceIdHashPageQueryResult = Apollo.QueryResult<VacanPlaceIdHashPageQuery, VacanPlaceIdHashPageQueryVariables>;
export const VacanTargetKeyPageDocument = gql`
    query VacanTargetKeyPage {
  vacanTargetKeys {
    id
    targetKey
    vacanPlaceUsed
    vacanPlaceTotal
  }
}
    `;

/**
 * __useVacanTargetKeyPageQuery__
 *
 * To run a query within a React component, call `useVacanTargetKeyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanTargetKeyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVacanTargetKeyPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useVacanTargetKeyPageQuery(baseOptions?: Apollo.QueryHookOptions<VacanTargetKeyPageQuery, VacanTargetKeyPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanTargetKeyPageQuery, VacanTargetKeyPageQueryVariables>(VacanTargetKeyPageDocument, options);
      }
export function useVacanTargetKeyPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanTargetKeyPageQuery, VacanTargetKeyPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanTargetKeyPageQuery, VacanTargetKeyPageQueryVariables>(VacanTargetKeyPageDocument, options);
        }
export type VacanTargetKeyPageQueryHookResult = ReturnType<typeof useVacanTargetKeyPageQuery>;
export type VacanTargetKeyPageLazyQueryHookResult = ReturnType<typeof useVacanTargetKeyPageLazyQuery>;
export type VacanTargetKeyPageQueryResult = Apollo.QueryResult<VacanTargetKeyPageQuery, VacanTargetKeyPageQueryVariables>;
export const GenerateQrPageDocument = gql`
    query GenerateQrPage {
  productProvider {
    id
    name
  }
  ticketUsageOptions {
    id
    nameJa
    tickets {
      id
      nameJa
      product {
        id
        nameJa
      }
    }
  }
}
    `;

/**
 * __useGenerateQrPageQuery__
 *
 * To run a query within a React component, call `useGenerateQrPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateQrPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateQrPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateQrPageQuery(baseOptions?: Apollo.QueryHookOptions<GenerateQrPageQuery, GenerateQrPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateQrPageQuery, GenerateQrPageQueryVariables>(GenerateQrPageDocument, options);
      }
export function useGenerateQrPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateQrPageQuery, GenerateQrPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateQrPageQuery, GenerateQrPageQueryVariables>(GenerateQrPageDocument, options);
        }
export type GenerateQrPageQueryHookResult = ReturnType<typeof useGenerateQrPageQuery>;
export type GenerateQrPageLazyQueryHookResult = ReturnType<typeof useGenerateQrPageLazyQuery>;
export type GenerateQrPageQueryResult = Apollo.QueryResult<GenerateQrPageQuery, GenerateQrPageQueryVariables>;
export const SimplePassMyPassesPageDocument = gql`
    query SimplePassMyPassesPage($status: [SimplePassMyPassStatus!]!, $direction: Direction, $userGivenName: String, $userFamilyName: String, $origin: String, $destination: String, $passTypeName: String, $from: ISO8601Date, $to: ISO8601Date, $page: Int, $size: Int) {
  simplePassMyPasses(
    status: $status
    direction: $direction
    userGivenName: $userGivenName
    userFamilyName: $userFamilyName
    origin: $origin
    destination: $destination
    passTypeName: $passTypeName
    from: $from
    to: $to
    page: $page
    size: $size
  ) {
    data {
      id
      statusName
      createdAt
      userGivenName
      userFamilyName
      usageStartOn
      originDestination {
        origin {
          ...SimplePassOriginFields
        }
        destination {
          ...SimplePassDestinationFields
        }
      }
      passPeriod {
        periodType
      }
      passType {
        name
        ageCategoryName
      }
    }
    totalCount
    size
    page
  }
}
    ${SimplePassOriginFieldsFragmentDoc}
${SimplePassDestinationFieldsFragmentDoc}`;

/**
 * __useSimplePassMyPassesPageQuery__
 *
 * To run a query within a React component, call `useSimplePassMyPassesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassMyPassesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassMyPassesPageQuery({
 *   variables: {
 *      status: // value for 'status'
 *      direction: // value for 'direction'
 *      userGivenName: // value for 'userGivenName'
 *      userFamilyName: // value for 'userFamilyName'
 *      origin: // value for 'origin'
 *      destination: // value for 'destination'
 *      passTypeName: // value for 'passTypeName'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSimplePassMyPassesPageQuery(baseOptions: Apollo.QueryHookOptions<SimplePassMyPassesPageQuery, SimplePassMyPassesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassMyPassesPageQuery, SimplePassMyPassesPageQueryVariables>(SimplePassMyPassesPageDocument, options);
      }
export function useSimplePassMyPassesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassMyPassesPageQuery, SimplePassMyPassesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassMyPassesPageQuery, SimplePassMyPassesPageQueryVariables>(SimplePassMyPassesPageDocument, options);
        }
export type SimplePassMyPassesPageQueryHookResult = ReturnType<typeof useSimplePassMyPassesPageQuery>;
export type SimplePassMyPassesPageLazyQueryHookResult = ReturnType<typeof useSimplePassMyPassesPageLazyQuery>;
export type SimplePassMyPassesPageQueryResult = Apollo.QueryResult<SimplePassMyPassesPageQuery, SimplePassMyPassesPageQueryVariables>;
export const SimplePassPurchaseDetailsDashboardDocument = gql`
    query SimplePassPurchaseDetailsDashboard($startOn: ISO8601Date!, $endOn: ISO8601Date!, $simplePassPassGroupIds: [ID!]!) {
  simplePassPurchaseDetailsDashboard(
    startOn: $startOn
    endOn: $endOn
    simplePassPassGroupIds: $simplePassPassGroupIds
  ) {
    url
  }
}
    `;

/**
 * __useSimplePassPurchaseDetailsDashboardQuery__
 *
 * To run a query within a React component, call `useSimplePassPurchaseDetailsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassPurchaseDetailsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassPurchaseDetailsDashboardQuery({
 *   variables: {
 *      startOn: // value for 'startOn'
 *      endOn: // value for 'endOn'
 *      simplePassPassGroupIds: // value for 'simplePassPassGroupIds'
 *   },
 * });
 */
export function useSimplePassPurchaseDetailsDashboardQuery(baseOptions: Apollo.QueryHookOptions<SimplePassPurchaseDetailsDashboardQuery, SimplePassPurchaseDetailsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassPurchaseDetailsDashboardQuery, SimplePassPurchaseDetailsDashboardQueryVariables>(SimplePassPurchaseDetailsDashboardDocument, options);
      }
export function useSimplePassPurchaseDetailsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassPurchaseDetailsDashboardQuery, SimplePassPurchaseDetailsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassPurchaseDetailsDashboardQuery, SimplePassPurchaseDetailsDashboardQueryVariables>(SimplePassPurchaseDetailsDashboardDocument, options);
        }
export type SimplePassPurchaseDetailsDashboardQueryHookResult = ReturnType<typeof useSimplePassPurchaseDetailsDashboardQuery>;
export type SimplePassPurchaseDetailsDashboardLazyQueryHookResult = ReturnType<typeof useSimplePassPurchaseDetailsDashboardLazyQuery>;
export type SimplePassPurchaseDetailsDashboardQueryResult = Apollo.QueryResult<SimplePassPurchaseDetailsDashboardQuery, SimplePassPurchaseDetailsDashboardQueryVariables>;
export const SimplePassPurchaseDetailsPageDocument = gql`
    query SimplePassPurchaseDetailsPage {
  simplePassPassGroups {
    id
    name
  }
}
    `;

/**
 * __useSimplePassPurchaseDetailsPageQuery__
 *
 * To run a query within a React component, call `useSimplePassPurchaseDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplePassPurchaseDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplePassPurchaseDetailsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimplePassPurchaseDetailsPageQuery(baseOptions?: Apollo.QueryHookOptions<SimplePassPurchaseDetailsPageQuery, SimplePassPurchaseDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplePassPurchaseDetailsPageQuery, SimplePassPurchaseDetailsPageQueryVariables>(SimplePassPurchaseDetailsPageDocument, options);
      }
export function useSimplePassPurchaseDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplePassPurchaseDetailsPageQuery, SimplePassPurchaseDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplePassPurchaseDetailsPageQuery, SimplePassPurchaseDetailsPageQueryVariables>(SimplePassPurchaseDetailsPageDocument, options);
        }
export type SimplePassPurchaseDetailsPageQueryHookResult = ReturnType<typeof useSimplePassPurchaseDetailsPageQuery>;
export type SimplePassPurchaseDetailsPageLazyQueryHookResult = ReturnType<typeof useSimplePassPurchaseDetailsPageLazyQuery>;
export type SimplePassPurchaseDetailsPageQueryResult = Apollo.QueryResult<SimplePassPurchaseDetailsPageQuery, SimplePassPurchaseDetailsPageQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    